import React, { useEffect } from 'react';

const TermsofUse = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the FeedBack component mounts
    });
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center mb-32">
        <div className="shadow-lg bg-slate-50 justify-between items-stretch max-w-[1170px] rounded-xl mt-40 flex w-full gap-5 px-10 py-9 max-md:max-w-full max-md:flex-wrap max-md:px-5">
          <div className="flex flex-col items-stretch max-md:max-w-full">
            <div className="text-slate-500 text-lg leading-5 max-md:max-w-full">TERMS OF USE</div>
            <div className="text-primary1 text-3xl font-bold leading-[49.92px] mt-6 max-md:max-w-full max-md:text-4xl">JOHN L. SCOTT® REAL ESTATE WEBSITE TERMS OF USE</div>
            <div className="text-slate-900 text-lg leading-8 mt-3 max-md:max-w-full">
              {/* Opening Doors to your Dream.
                        <br /> */}
              Let’s Make it Reality!
            </div>
            <div>
              <p className="mt-5 leading-6">Welcome to the John L. Scott Real Estate website (the “Site”). This Site is operated for and by John L. Scott, Inc. (“John L. Scott”). Your use of the Site is subject to your compliance with these Terms of Use (these “Terms”). By accessing and using the Site, you accept and agree to be bound by these Terms and the John L. Scott Privacy Policy located below. If you do not agree to these Terms and the Privacy Policy, you may not use the Site. John L. Scott may amend and/or modify these Terms and the Privacy Policy from time to time and without notice, effective upon posting on the Site, and your continued access and use of the Site constitutes acceptance of all such modifications</p>
            </div>
            <div>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Privacy</h2>
              <p className="mt-3 ">John L. Scott respects your privacy. Please review our Privacy Policy located below for more information. The Privacy Policy is incorporated into and made a part of these Terms, as amended or modified from time to time.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Accuracy</h2>
              <p className="mt-3 ">John L. Scott attempts to ensure that the content, property and other product descriptions and other information on the Site are as accurate as possible. However, John L. Scott does not warrant or guarantee that any information on the Site is accurate, complete or error-free, and John L. Scott undertakes no commitment or obligation to update any content, property and other product descriptions or other information on the Site. John L. Scott may at any time change the contents, property and other product descriptions or other information on the Site, and/or any aspect of the Site, without prior notice.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">School Data</h2>
              <p className="mt-3 ">School data is provided by GreatSchools and is subject to GreatSchools terms of use. School data is provided only for general reference and should not be relied upon without your independent verification. John L. Scott is not affiliated with GreatSchools nor third party providers of service boundaries and does not warrant or guarantee the accuracy of the school data provided. To verify enrollment eligibility and related information, contact the school, school district, or relevant government agency directly.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Proprietary Rights</h2>
              <p className="mt-3 ">Proprietary Rights All materials on the Site, including all text, graphics, logos, images and any other material or information published on or used in connection with the Site (collectively, the “Site Content” ) are the property of John L. Scott or its licensors. The Site Content is protected by copyright laws and treaties around the world, and all rights in and to the Site Content are expressly reserved. Any use of the Site Content not permitted under these Terms is strictly forbidden. If you breach any provision of these Terms or make any use of the Site Content not permitted by these Terms, we may automatically terminate and cancel your authorization to visit the Site and you must, at our sole option, immediately return or destroy any material printed or downloaded from the Site.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Limited License</h2>
              <p className="mt-3 ">Subject to the terms and conditions set forth in these Terms, John L. Scott grants you a personal, non-transferable and non-exclusive limited license to access and use the Site and to download and print a single copy of the Site Content solely for your personal, non-commercial use, and provided that you do not delete or modify any copyright or other proprietary notices. Any other copying, use, distribution, publication, transmission or modification of the Site Content or any other property of John L. Scott, whether in electronic or hard copy form, is strictly prohibited. This prohibition includes screen or database scraping for any purpose.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Property Tracker®, Market Watch Eligibility and Registration Data</h2>
              <p className="mt-3 ">You must be 18 years of age or older to use Market Watch. To use certain features of the Site, including Property Tracker® and/or Market Watch, you must register for an account. By registering for an account, you represent and warrant that any information that you submit is true and accurate, that you have not previously been suspended or removed from Market Watch, that you are not a direct competitor of JLS or any JLS Affiliate and that you are fully able and competent to enter into, and abide by these Terms. You further agree to (i) provide accurate, current and complete information about you as may be prompted by any signup, login and/or registration forms within our registration process (“Registration Data”); (ii) maintain the security of your password and identification; (iii) maintain and promptly update the Registration Data, and any other information you provide to us, to keep it accurate, current and complete; (iv) accept all risks of unauthorized access to the Registration Data and any other information you provide to us; and (v) notify us immediately of any unauthorized use of your account or any other breach of security by emailing us at contactus@johnlscott.com. Use of the Site, Property Tracker® and/or Market Watch is void where prohibited.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">User Content</h2>
              <p className="mt-3 ">The Site may now or in the future allow users to select, input, upload and share information and data in connection with the Site, including via Property Tracker® and/or Market Watch, and may now or in the future allow you or other users to post, link, store or otherwise make available a wide variety of information, text and/or other materials to the user or others in connection with the use of the Site or otherwise (collectively, “User Content”). You are solely responsible for your use of any User Content, and you use such User Content at your own risk. By posting any User Content, you represent and warrant that you own and control all of the rights to the User Content that you post, or that you otherwise have the lawful right to distribute and reproduce such User Content and to grant John L. Scott the license for the User Content described below and that your use and posting of such User Content does not violate these Terms, will not violate any rights of or cause injury to any person or entity, and will not otherwise create any harm or liability of any type for us or for any third party. By selecting and/or posting any User Content, you agree not to post, upload to, transmit, distribute, store, create or otherwise publish through the Site, any of the following:</p>
              <ul className="list-disc mt-3">
                <li className="mt-1">User Content that is unlawful, libelous, defamatory, obscene, pornographic, harassing, threatening, invasive of privacy or publicity rights, deceptive, fraudulent or otherwise objectionable (as determined in our sole discretion)</li>
                <li className="mt-1">User Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party or violate any local, state, national or international law</li>
                <li className="mt-1">User Content that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party</li>
                <li className="mt-1">User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity; or that provides private information of any third party including addresses, phone numbers, email addresses, Social Security numbers and similar information</li>
                <li className="mt-1">User Content that, in our sole discretion, is objectionable or which restricts or inhibits any other person from using and/or benefiting from our Site, including, without limitation, User Content that contains or installs any viruses, worms, malware, Trojan horses or other harmful or destructive content</li>
                <li className="mt-1">User Content that is spam, is machine- or randomly-generated, contains unethical or unwanted commercial content, furthers unlawful acts (such as phishing) or misleads recipients as to the source of the material (such as spoofing)</li>
                <li className="mt-1">User Content that in any way refers to or depicts persons under 18 years of age or</li>
                <li className="mt-1">User Content that in any way refers to or depicts persons under 18 years of age or</li>
              </ul>
              <p className="pt-2">We take no responsibility and assume no liability for any User Content posted, stored or uploaded by you or any third party, or for any loss or damage thereto, nor are we liable for any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography or profanity you may encounter.</p>
              <p className="pt-2">As a provider of interactive services such as Property Tracker® and Market Watch, we are not liable for any statements, representations, User Content or Advertiser Content (as defined below) provided by users in any form whatsoever on any area of the Site or through any Property Tracker® and/or Market Watch communications. Although we have no obligation to screen, edit or monitor any of the User Content or Advertiser Content posted anywhere on or through the Site, we reserve the right, and have absolute discretion, to remove, screen or edit any User Content and Advertiser Content posted or stored on or through the Site at any time and for any reason without notice. You are solely responsible for creating backup copies of and replacing any User Content you post or store on or through the Site, at your sole cost and expense.</p>
              <p className="pt-2">In posting and/or otherwise submitting User Content to the Site or through Property Tracker® and/or Market Watch, you grant us an irrevocable, worldwide, transferable, nonexclusive, royalty-free and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such User Content and any information, data or other material generated through the use of the Site on or in any and all media (now known or hereafter developed). You also hereby grant each user of the Site, Property Tracker® and/or Market Watch a non-exclusive license to access your User Content and any information, data or other material generated through the use of the Site, Property Tracker® and/or Market Watch and to use, reproduce, distribute, prepare derivative works of, display and perform such User Content and information, data or other material generated through the use of the Site, Property Tracker® and/or Market Watch under these Terms.</p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Third Party Links</h2>
              <p className="mt-3 ">Where the Site contains links to other sites and resources provided by third parties, these links are provided for your information only. We have not performed any check on sites that are linked by or accessible from the Site, nor are we responsible for how such sites are managed, their content and their availability to use. We have no control over the content of those sites or the resources available from those sites, and accept no responsibility for them or for any losses or damages that may arise from your use of such sites. If you decide to access any website by a link from the Site, you do so exclusively at your own responsibility and risk.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Advertisements and Promotions</h2>
              <p className="mt-3 ">We may now or in the future allow advertisers and corporate partners to post content on the Site and provide links to sites and content of third parties (collectively the “Advertiser Content”) as a service to those interested in this information. Advertiser Content may be targeted to users based on information they provide through use of the Site or other information. We do not control, endorse or adopt any Advertiser Content and we make no representation or warranties of any kind regarding the Advertiser Content. The types and extent of advertising on the Site are subject to change.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Infringing Materials, Copyright Complaints</h2>
              <p className="mt-3 ">See this information under the DMCA Notice on our website under http://www.johnlscott.com/dmca.aspx.</p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Applicable Law, Disputes</h2>
              <p className="mt-3 ">These Terms, together with the Privacy Policy, constitute the entire agreement between you and John L. Scott concerning your use of the Site, superseding any prior agreements between you and John L. Scott with respect to the Site. These Terms and the relationship between you and John L. Scott shall be subject to the internal laws of the State of Washington without giving effect to its principles on conflict of laws. By accessing the Site you irrevocably agree that all disputes will be settled exclusively by the state and federal courts located in Washington.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;

import React from 'react';
import { Link } from 'react-router-dom';

const HelpDv = () => {
  return (
    <div className="flex w-full max-w-[1170px] flex-col items-stretch my-8 max-md:max-w-full justify-center mx-auto">
      <div className="text-slate-900 text-3xl font-bold leading-10 text-center max-md:max-w-full">
        <p>See how DV Homes can Help</p>
      </div>
      <div className="text-slate-500 text-lg leading-6 text-center whitespace-nowrap mt-3">Explore what services we offer</div>
      <div className="mt-12 max-md:max-w-full max-md:mt-10">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 justify-center mx-auto ">
          <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0  ">
            <div className="items-center border bg-stone-50 flex w-full grow flex-col  mx-auto px-6 py-7 rounded-lg border-solid max-md:mt-6 max-md:px-5 border-transparent hover:border-secondary1">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/55e0fd1bd2fadb98e18a3e9cf190260058ec8df7e4f149e66129cc1a58549c10?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full" alt="dv" />
              <div className="text-slate-900 text-center text-base font-bold leading-6 whitespace-nowrap mt-6">Buy a property</div>
              <div className="self-stretch text-gray-600 text-center text-base leading-6 mt-2.5">
                Discover homes that fit
                <br />
                your future!
              </div>
              <a href="https://dvhomes.idxbroker.com/idx/map/mapsearch">
                <button className="justify-between self-stretch transition duration-500 hover:bg-secondary1 bg-primary1 flex gap-2.5 mt-6 px-12 py-4 rounded-lg items-start max-md:px-5">
                  <div className="text-zinc-50 text-center text-base leading-5 grow whitespace-nowrap">Find a home</div>
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6899f48b97ff89dac856ab6c5621b7f0c75929ba26aa31140fdde95a70ab1b6?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                </button>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="items-center bg-stone-50 flex w-full grow flex-col mx-auto px-6 py-7 rounded-lg max-md:mt-6 border max-md:px-5 border-transparent hover:border-secondary1">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1bef0544a41ba57246cc874653151efc74ec69b6299fd0dc7c1be23e7865ec05?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full" alt="dv" />
              <div className="text-slate-900 text-center text-base font-bold leading-6 whitespace-nowrap mt-6">Sell a property</div>
              <div className="self-stretch text-gray-600 text-center text-base leading-6 mt-2.5">Empowering your next chapter.</div>
              <Link to="/sell">
                <button className="justify-between self-stretch transition duration-500 hover:bg-secondary1 bg-primary1 border  flex gap-2.5 mt-6 px-14 py-4 rounded-lg border-solid items-start max-md:px-5">
                  <div className="text-zinc-50 text-center text-base leading-5 grow whitespace-nowrap">Place an Ad</div>
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6899f48b97ff89dac856ab6c5621b7f0c75929ba26aa31140fdde95a70ab1b6?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                </button>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="items-center bg-stone-50 flex w-full grow flex-col mx-auto px-6 py-7 rounded-lg max-md:mt-6 max-md:px-5 border border-transparent hover:border-secondary1">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/467f3ce99db7b74f73d1c3c36aecde18bc6a4d9a0ba6544621db6eeea54366eb?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full" alt="dv" />
              <div className="text-slate-900 text-center text-base font-bold leading-6 whitespace-nowrap mt-6">Home Valuation</div>
              <div className="self-stretch text-gray-600 text-center text-base leading-6 mt-2.5">Evaluate your investment potential.</div>
              <a href="https://dvhomes.idxbroker.com/idx/homevaluation">
                <button className="justify-between self-stretch transition duration-500  hover:bg-secondary1 bg-primary1 border flex gap-2.5 mt-6 px-12 py-4 rounded-lg border-solid items-start max-md:px-5">
                  <div className="text-zinc-50 text-center text-base leading-5 grow whitespace-nowrap">Evaluate</div>
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6899f48b97ff89dac856ab6c5621b7f0c75929ba26aa31140fdde95a70ab1b6?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                </button>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="items-center bg-stone-50 flex w-full grow flex-col mx-auto px-6 py-7 rounded-lg max-md:mt-6 max-md:px-5 border border-transparent hover:border-secondary1">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb711f502a09b4e5ba49b628c478afb22" className="aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full" alt="dv" />
              <div className="text-slate-900 text-center text-base font-bold leading-6 whitespace-nowrap mt-6">Market Report</div>
              <div className="self-stretch text-gray-600 text-center text-base leading-6 mt-2.5">Insights beyond listing potential.</div>
              <a href="https://dvhomes.idxbroker.com/idx/market-reports">
                <button className="justify-between self-stretch  transition duration-500 hover:bg-secondary1 bg-primary1 border flex gap-2.5 mt-6 px-12 py-4 rounded-lg border-solid items-start max-md:px-5">
                  <div className="text-zinc-50 text-center text-base leading-5 grow whitespace-nowrap">Know Market</div>
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6899f48b97ff89dac856ab6c5621b7f0c75929ba26aa31140fdde95a70ab1b6?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HelpDv;

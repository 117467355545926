import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import LeftArrow from '../../../src/assets/left-arrow.svg';
import RightArrow from '../../../src/assets/right-arrow.svg';
import axios from 'axios';
import ReactLoading from 'react-loading';

const ReviewCarousal = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://dv-homes-front.vercel.app/api/testimonial/approved');
      setData(response.data.data ?? []);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => <img src={LeftArrow} alt="prevArrow" {...props} />;

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => <img src={RightArrow} alt="nextArrow" {...props} />;

  const settings = {
    // autoplay : true,
    loop: true,
    // dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="">
      <div className="flex justify-center items-center">
        <div className="header bg-white self-center w-[100%] max-w-[1170px] mt-1 pt-6 -mb-16 pb-9 px-10 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5">
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Take the full height of the viewport
              }}
            >
              <ReactLoading color={'blue'} height={'5%'} width={'5%'} />
            </div>
          ) : (
            <Slider {...settings}>
              {data &&
                data.length >= 1 &&
                data.map((data) => (
                  <div key={data.id} className="flex flex-col items-stretch w-[350px] p-2 h-56 overflow-hidden max-md:w-full max-md:ml-0">
                    <div className="self-stretch border border-[color:var(--Line,#D6D8E7)] flex w-full h-52 sm:h-56  grow flex-col  mx-auto pt-6 pb-12 px-6 rounded-lg border-solid items-start max-md:mt-6 max-md:px-1">
                      <div className="items-stretch self-stretch flex justify-between gap-2.5">
                        <div className="justify-center items-stretch flex grow basis-[0%] flex-col my-auto">
                          <div className="text-slate-900 text-lg font-semibold leading-5">{data.customerName}</div>
                          <div className="text-slate-900 text-base leading-6 mt-1">{data.location}</div>
                        </div>
                      </div>
                      <div>
                        {[1, 2, 3, 4, 5].map((value) => (
                          <span
                            key={value}
                            style={{
                              margin: 3,
                              fontSize: 14,
                              color: value <= data.rating ? 'gold' : 'gray',
                            }}
                          >
                            {value <= data.rating ? '\u2605' : '\u2606'}
                          </span>
                        ))}
                      </div>
                      <p className="self-stretch text-gray-600 text-sm leading-5 mt-4 mb-3.5">&quot;{data.description} &quot;</p>
                    </div>
                  </div>
                ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewCarousal;

import axios from 'axios';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const FormSell = () => {
  const showAlert = () => {
    Swal.fire({
      title: 'SUCCESS',
      text: 'Message sent successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    date: '',
    category: '',
    address: '',
    message: '',
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      firstname: '',
      lastname: '',
      number: '',
      email: '',
      date: '',
      category: '',
      address: '',
      message: '',
    };

    // Add your validation logic here
    const nameRegex = /^[a-zA-Z]{3,}$/;
    if (!formData.firstname.trim() || !nameRegex.test(formData.firstname.trim())) {
      newErrors.firstname = 'First name is required';
      isValid = false;
    }

    if (!formData.lastname.trim()) {
      newErrors.lastname = 'Last name is required';
      isValid = false;
    }

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (!formData.number.trim() || !phoneRegex.test(formData.number.trim())) {
      newErrors.number = 'Enter a valid Mobile Number';
      isValid = false;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      newErrors.email = 'Enter a valid Email address ';
      isValid = false;
    }

    if (!selectedDate) {
      newErrors.date = 'Date is required';
      isValid = false;
    }
    if (!selectedCategory) {
      newErrors.category = 'Category is required';
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    address: '',
    message: '',
  });

  const [selectedCategory, setSelectedCategory] = useState('');

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    await axios.post('https://dv-homes-front.vercel.app/api/appointment', {
      firstName: formData.firstname,
      lastName: formData.lastname,
      phone: formData.number,
      email: formData.email,
      address: formData.address,
      message: formData.message,
      category: selectedCategory,
      date: selectedDate,
    });

    showAlert();

    setFormData({
      firstname: '',
      lastname: '',
      number: '',
      email: '',
      address: '',
      message: '',
    });

    setSelectedCategory('');
    setSelectedDate(new Date());
  };

  //date selection for future only

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  //ends here

  return (
    <div className="flex justify-center items-center">
      <div className="items-start self-center bg-stone-50 flex w-full max-w-[1170px] flex-col mt-10 mb-10 p-10 rounded-xl max-md:max-w-full max-md:px-5">
        <div className="text-slate-900 text-2xl  font-bold leading-9 self-start">Connect with dream team.</div>
        <div className="text-slate-500 text-lg leading-6 whitespace-nowrap mt-2 self-start">Fill out the form, and we'll be in touch soon!</div>
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="self-stretch bg-slate-300 flex shrink-0 h-px flex-col  mt-6 max-md:max-w-full" />

          <div className="items-stretch self-stretch flex justify-between  gap-4 mt-6 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label for="firstname" className="">
                  First name
                </label>
              </div>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                placeholder="Enter your first name"
                // required
                className=" text-base leading-6 whitespace-nowrap justify-center border   border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5"
              ></input>
              <div className="text-rose-800">{error.firstname}</div>
            </div>
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label for="lastname" className="">
                  Last name
                </label>
              </div>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                placeholder="Enter your last name"
                // required
                className=" text-base leading-6 whitespace-nowrap justify-center border   border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5"
              ></input>
              <div className="text-rose-800">{error.lastname}</div>
            </div>
          </div>

          <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label for="number" className="">
                  Phone number
                </label>
              </div>
              <input
                type="tel"
                id="number"
                pattern="[0-9]{10}"
                name="number"
                value={formData.number}
                onChange={handleInputChange}
                placeholder="Enter your Mobile number"
                // required
                className=" text-base leading-6 whitespace-nowrap justify-center border   border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5"
              ></input>
              <div className="text-rose-800">{error.number}</div>
            </div>
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label for="email" className="">
                  Email
                </label>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email address"
                // required
                className=" text-base leading-6 whitespace-nowrap justify-center border   border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5"
              ></input>
              <div className="text-rose-800">{error.email}</div>
            </div>
          </div>
          <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
            {/*<div className='items-stretch flex grow basis-[0%] flex-col max-md:max-w-full'>
              <div className='text-red-500 text-base leading-6 max-md:max-w-full'>
                <div className='flex flex-col'>
                  <span className='text-white text-base leading-6 mb-1'>
                    Select category
                  </span>
                  <div className='flex gap-2.5 mt-2.5 py-1 self-start'>
                    <label
                      className={`category-button ${
                        selectedCategory === 'buy'
                          ? 'bg-secondary1 text-zinc-50'
                          : 'bg-white text-slate-900'
                      } text-white text-base leading-6 whitespace-nowrap justify-center items-stretch border transition duration-500 hover:bg-slate-200 border-slate-400 hover:border-zinc-950 grow px-6 py-3.5 rounded-full max-md:px-5`}
                    >
                      <input
                        type='radio'
                        name='category'
                        value='buy'
                        // required
                        onChange={() => setSelectedCategory('buy')}
                        className='hidden '
                      />
                      Buy
                    </label> 
                    <label
                      className={`category-button ${
                        selectedCategory === 'sell'
                          ? 'bg-secondary1 text-zinc-50'
                          : 'bg-white text-slate-900'
                      } text-white text-base leading-6 whitespace-nowrap justify-center border border-slate-400 transition duration-500 hover:bg-slate-200 hover:border-zinc-950 items-stretch grow px-6 py-3.5 rounded-full max-md:px-5`}
                    >
                      <input
                        type='radio'
                        name='category'
                        value='sell'
                        // required
                        onChange={() => setSelectedCategory('sell')}
                        className='hidden'
                      />
                      Sell
                    </label>
                     <label
                      className={`category-button ${
                        selectedCategory === 'invest'
                          ? 'bg-secondary1 text-zinc-50'
                          : 'bg-white text-slate-900'
                      } text-white text-base leading-6 whitespace-nowrap justify-center items-stretch border transition duration-500  hover:bg-slate-200 border-slate-400 hover:border-zinc-950 grow px-6 py-3.5 rounded-full max-md:px-5`}
                    >
                      <input
                        type='radio'
                        name='category'
                        value='invest'
                        // required
                        onChange={() => setSelectedCategory('invest')}
                        className='hidden'
                      />
                      Invest
                    </label> 
                  </div>
                  <div className="text-rose-800">{error.category}</div>
                </div>
              </div>
            </div>*/}
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label for="date" className="">
                  Select date
                </label>
              </div>
              <div className="bg-white w-full flex gap-2.5 mt-2.5 rounded-lg items-start max-md:max-w-full max-md:flex-wrap ">
                <DatePicker selected={selectedDate} onChange={handleDateChange} value={selectedDate} placeholderText="Select date" className="text-base cursor-pointer leading-6 grow max-md:max-w-full lg:w-full py-3.5 w-full pl-4 max-md:px-5 focus:outline-none border text-pretty border-slate-400 rounded-md" minDate={new Date()} />
                <div className="text-rose-800">{error.date}</div>
                {/* <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/7cc7cb52dd22103a7ed2550e19fac9404a7a82f350601417bb3e75326f2086c6?apiKey=60bd44a890ac4356aa91264734464f16&' // Replace with your icon URL
                  alt='choose a date'
                  className='cursor-pointer aspect-square object-contain w-6'
                  onClick={() => {
                    alert('Calendar opened!');
                  }}
                /> */}
              </div>
            </div>
          </div>
          <div className=" mb-6 text-red-500 text-base leading-6 self-stretch mt-4 max-md:max-w-full">
            <label for="address" className="">
              Property Address
            </label>
          </div>
          <textarea textarea id="address" name="address" value={formData.address} onChange={handleInputChange} placeholder="Enter your current address" className="mt-1 p-3 w-full  md:max-w-full border border-slate-400 rounded-md"></textarea>
          <div className="text-rose-800">{error.address}</div>

          <div class="mb-6 ">
            <label for="message" class="block text-slate-900 text-base leading-6">
              Message
            </label>
            <textarea id="message" name="message" value={formData.message} onChange={handleInputChange} placeholder="Enter your message" className="mt-6 p-3 w-full  md:max-w-full border border-slate-400 rounded-md"></textarea>
            <div className="text-rose-800">{error.message}</div>
          </div>

          <button className="text-zinc-50 text-base leading-6 whitespace-nowrap justify-center transition duration-500  bg-primary1 hover:bg-secondary1 mt-6 px-4 py-3.5 rounded-[45px] self-start max-md:px-5">Send Message</button>
        </form>
      </div>
    </div>
  );
};
export default FormSell;

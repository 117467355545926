import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { FaChevronUp, FaWineGlassAlt, FaHiking, FaSwimmer } from 'react-icons/fa';
import './LakeChelan.css';
import LakeCarousel from './LakeCarousel';

const LakeChelan = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const heroRef = useRef(null);
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);

  useEffect(() => {
    const handleScroll = () => {
      const heroHeight = heroRef.current.offsetHeight;
      setIsNavVisible(window.scrollY > heroHeight - 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleBookNow = () => {
    window.location.href = 'https://www.grandviewonthelake.com/vacation-rentals/rental/515903/';
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ParallaxProvider>
      <div className="lake-chelan-experience">
        <nav className={`navbar ${isNavVisible ? 'visible' : ''}`}>
          <div className="nav-content">
            <h3>Lake Chelan Experience</h3>
            <button className="btn-primary" onClick={handleBookNow}>
              BOOK NOW
            </button>
          </div>
        </nav>

        <header className="hero" ref={heroRef}>
          <Parallax translateY={[0, 50]} opacity={[1, 0.9]}>
            <motion.div className="hero-content" style={{ opacity }}>
              <motion.h1 initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                Lake Chelan Experience
              </motion.h1>
              <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1 }}>
                Discover the ultimate getaway in the heart of Washington's wine country
              </motion.p>
              <motion.button className="btn-primary" onClick={handleBookNow} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 1 }} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                BOOK NOW
              </motion.button>
            </motion.div>
          </Parallax>
        </header>

        <main>
          <section className="experience-section">
            <Parallax>
              <div className="card">
                <div className="card-content">
                  <h2>Experience the Extraordinary</h2>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center">
                  <div className="w-full md:w-1/2 px-8 md:px-16 flex  flex-col justify-center items-center">
                    {' '}
                    <p className="text-center text-lg">We don't just sell houses. Creating the ultimate experience is our top goal so our clients can look back on buying or selling their most prized possession and think "Wow, that was incredible!" Our clients get VIP access to our waterfront condo on Lake Chelan and all the thrilling adventures that come with it.</p>
                    {/* <motion.button 
                      className="btn-secondary my-6" 
                      onClick={handleBookNow}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      BOOK YOUR ADVENTURE
                    </motion.button> */}
                    <button onClick={handleBookNow} className="relative justify-center text-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 self-center flex  max-w-full flex-col mt-10 mb-4 px-9 py-4 rounded-[32px] max-md:px-5">
                      <div className="items-stretch flex justify-between gap-1.5 py-0.5">
                        <div className="text-zinc-50 text-center text-base leading-6 grow whitespace-nowrap">BOOK YOUR ADVENTURE</div>
                      </div>
                    </button>
                  </div>
                  <div className="card-image w-full md:w-1/2">
                    <img src="/img/lc2.jpg" alt="Luxurious Condo Interior" />
                  </div>
                </div>
              </div>
            </Parallax>
          </section>
          <section className="activities-section ">
            <h2>Unforgettable Experiences</h2>
            <div className="activities-grid adjustment">
              <motion.div className="activity-card flex flex-col justify-center items-center" whileHover={{ scale: 1.05 }} transition={{ type: 'spring', stiffness: 300 }}>
                <FaWineGlassAlt className="activity-icon" />
                <h3>Wine Tasting</h3>
                <p>Savor the flavors of local wineries and vineyards in the heart of Washington's wine country.</p>
                <a href="https://www.seattlemet.com/travel-and-outdoors/best-wineries-in-lake-chelan-wine-tasting" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mt-auto">
                  Learn More about Wineries
                </a>
              </motion.div>
              <motion.div className="activity-card flex flex-col justify-center items-center" whileHover={{ scale: 1.05 }} transition={{ type: 'spring', stiffness: 300 }}>
                <FaHiking className="activity-icon" />
                <h3>Scenic Hikes</h3>
                <p>Explore breathtaking trails with panoramic views of Lake Chelan and surrounding mountains.</p>
                <div className="flex flex-col items-center mt-2">
                  <a href="https://www.lakechelan.com/play/hiking/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mb-2">
                    Lake Chelan Hiking Trails
                  </a>
                  <a href="https://www.alltrails.com/us/washington/chelan" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    All Trails in Chelan
                  </a>
                </div>
              </motion.div>
              <motion.div className="activity-card flex flex-col justify-center items-center" whileHover={{ scale: 1.05 }} transition={{ type: 'spring', stiffness: 300 }}>
                <FaSwimmer className="activity-icon" />
                <h3>Water Activities</h3>
                <p>Enjoy jet skiing, boating, and swimming in the crystal-clear waters of Lake Chelan.</p>
                <div className="flex flex-col items-center mt-2">
                  <a href="https://chelanjetskis.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mb-2">
                    Boats & Jet Skis
                  </a>
                  <a href="https://www.chelanparasail.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Parasail Adventures
                  </a>
                </div>
              </motion.div>
            </div>
          </section>
          <section className="py-10">
            <LakeCarousel />
          </section>

          <section className="home-away-section py-16">
            <Parallax translateY={[-20, 20]} className="adjustment">
              <h2>Your Home Away From Home</h2>
              <div className="card-grid">
                <motion.div className="card " whileHover={{ scale: 1.05 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <h3 className="text-2xl">Relax in Luxury</h3>
                  <p className="leading-7">Our waterfront condo sleeps up to 8 guests comfortably and is located just 5 minutes from downtown. Whether you're looking for a romantic weekend escape or a getaway for the whole family, we have the perfect spot to relax and unwind.</p>
                </motion.div>
                <motion.div className="card" whileHover={{ scale: 1.05 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <h3 className="text-2xl">Explore Chelan</h3>
                  <p className="leading-7">Chelan, WA is a breathtaking city just a few hours east of Seattle with a large lake, surrounded by mountains and home to vineyards, wineries, restaurants, shopping, and so much more. Everything you need is within 5-10 minutes of our condo.</p>
                </motion.div>
              </div>
            </Parallax>
          </section>

          <section className="host-section">
            <Parallax>
              <div className="card">
                <div className="flex flex-col md:flex-row justify-center items-center md:items-end gap-4">
                  <div>
                    <img src="/img/about.png" alt="image" className="w-44 h-56" />
                  </div>
                  <div className="flex flex-row justify-center items-end">
                    <h2>Boney Mathew</h2>
                  </div>
                </div>
                <div className="host-content flex flex-col justify-center items-center">
                  <p className="leading-7 text-lg text-center">Boney Mathew at Dream Vision Homes has helped hundreds of clients throughout his 19-year career. If you're ready to make a move, give us a call to learn how we can get you the best results, whether you're buying, selling, or investing.</p>
                  {/* <motion.button 
                    className="btn-secondary my-6"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    CALL NOW
                  </motion.button> */}
                  <a href="tel:206-338-4663" target="_blank">
                    <button className="relative justify-center text-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 self-center flex max-w-full flex-col mt-10 mb-4 px-9 py-4 rounded-[32px] max-md:px-5">
                      <div className="items-stretch flex justify-between gap-1.5 py-0.5">
                        <div className="text-zinc-50 text-center text-base leading-6 grow whitespace-nowrap">CALL NOW</div>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </Parallax>
          </section>

          <section className="cta-section">
            <Parallax translateY={[-20, 20]}>
              <div className="card flex flex-col justify-center items-center">
                <h2>Book Your Lake Chelan Getaway</h2>
                <p>Experience the beauty of all seasons at Lake Chelan</p>
                <p>Secure your slice of paradise now and create unforgettable memories.</p>
                {/* <motion.button 
                  className="btn-secondary my-5" 
                  onClick={handleBookNow}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  BOOK YOUR STAY
                </motion.button> */}
                <button onClick={handleBookNow} className="relative justify-center text-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 self-center flex  max-w-full flex-col mt-10 mb-4 px-9 py-4 rounded-[32px] max-md:px-5">
                  <div className="items-stretch flex justify-between gap-1.5 py-0.5">
                    <div className="text-zinc-50 text-center text-base leading-6 grow whitespace-nowrap">BOOK YOUR STAY</div>
                  </div>
                </button>
              </div>
            </Parallax>
          </section>
        </main>

        <motion.button className="scroll-to-top" initial={{ opacity: 0 }} animate={{ opacity: scrollY > 200 ? 1 : 0 }} whileHover={{ scale: 1.1 }} onClick={scrollToTop}>
          <FaChevronUp />
        </motion.button>
      </div>
    </ParallaxProvider>
  );
};

export default LakeChelan;

const Data = () => {
  return (
    <div className="flex justify-center -mt-8 items-center">
      <div className="header bg-slate-100 self-center w-full max-w-[1170px] mt-14 pt-6 pb-9 px-10 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5">
        <div className="my-2.5 max-md:max-w-full">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
              <div className="justify-center items-center flex grow flex-col max-md:mt-9">
                <div className="text-primary1 text-4xl  leading-[50.16px] whitespace-nowrap">30+</div>
                <div className="self-stretch text-slate-500  lg:pb-6 text-center text-base font-medium leading-6 mt-2.5">Years of combined experience</div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="justify-center items-center flex flex-col max-md:mt-9">
                <div className="text-primary1 text-4xl leading-[50.16px] whitespace-nowrap">600+</div>
                <div className="self-stretch text-slate-500 text-center text-base font-medium leading-6  mt-2.5">Houses sold</div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="justify-center items-center flex flex-col max-md:mt-9">
                <div className="text-primary1 text-4xl leading-[50.16px] whitespace-nowrap">1000+</div>
                <div className="self-stretch text-slate-500 text-center text-base font-medium leading-6 whitespace-nowrap mt-2.5">Satisfied customers</div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="justify-center items-center flex grow flex-col max-md:mt-9">
                <div className="text-primary1 text-4xl leading-[50.16px] whitespace-nowrap">4+</div>
                <div className="self-stretch text-slate-500 text-center text-base font-medium leading-6 mt-2.5">
                  Years in the top 1% <br /> for agents in USA
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Data;

import React, { useState } from 'react';
import Slider from 'react-slick';
import ReactLoading from 'react-loading'; // For loading spinner
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const LakeCarousel = () => {
    const [isLoading, setIsLoading] = useState(false); // Assuming data loading is set to false
    const img = ["15", "16", "17", "18", "19", "20", "21", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "14"];

    // Custom Arrow for Left using FontAwesome
    const SlickArrowLeft = ({ ...props }) => (
        <div
            {...props}
            className="slick-prev absolute top-1/2 left-0 transform -translate-y-1/2 z-20 bg-transparent p-3 cursor-pointer hover:bg-transparent rounded-full transition-all duration-300"
            style={{ zIndex: 20 }}
        >
            <FontAwesomeIcon icon={faChevronLeft} className="text-3xl text-gray-700" />
        </div>
    );

    // Custom Arrow for Right using FontAwesome
    const SlickArrowRight = ({ ...props }) => (
        <div
            {...props}
            className="slick-next absolute top-1/2 right-0 transform -translate-y-1/2 z-20 bg-transparent p-3 cursor-pointer hover:bg-transparent rounded-full transition-all duration-300"
            style={{ zIndex: 20 }}
        >
            <FontAwesomeIcon icon={faChevronRight} className="text-3xl text-gray-700" />
        </div>
    );

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,          // Set autoplay speed (3 seconds)
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,  // Left arrow with FontAwesome
        nextArrow: <SlickArrowRight />, // Right arrow with FontAwesome
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2, dots: true } },
            { breakpoint: 600, settings: { slidesToShow: 2 } },
            { breakpoint: 480, settings: { slidesToShow: 1 } },
        ],
    };

    return (
        <div className="flex justify-center items-center">
            <div className="header bg-white self-center w-full max-w-[1170px] mt-1 pt-6 pb-9 px-10 rounded-xl max-md:px-7">
                {isLoading ? (
                    <div className="flex justify-center items-center h-[100vh]">
                        <ReactLoading color="blue" height="5%" width="5%" />
                    </div>
                ) : (
                    <Slider {...settings}>
                        {img.map((imgName, index) => (
                            <div key={index} className="flex items-stretch w-[450px] p-2 h-56 max-md:w-full">
                                <img src={`/img/lakechelan/${imgName}.jpg`} alt={`Lake Chelan ${imgName}`} />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    );
}

export default LakeCarousel;

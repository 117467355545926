import React, { useState, useEffect } from 'react';

const Faqs = () => {
  const [toggle, setToggle] = useState(false);
  const [index, setIndex] = useState();
  const ArrowClick = (id) => {
    setIndex(id);
    setToggle(!toggle);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the FeedBack component mounts
    });
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center mb-32">
        <div className="shadow-lg bg-slate-50 justify-between items-stretch max-w-[1170px] rounded-xl mt-40 flex w-full gap-5 px-10 py-9 max-md:max-w-full max-md:flex-wrap max-md:px-5">
          <div className="flex flex-col items-stretch max-md:max-w-full">
            <div className="text-slate-500 text-lg leading-5 max-md:max-w-full">FAQs</div>
            <h2 className="text-primary1 text-2xl mt-7 font-bold">1. What types of properties do you specialize in?</h2>

            <div>
              <p className="mt-5 leading-6">We specialize in a wide range of properties including residential homes, condos, townhouses, commercial buildings, and vacant land. </p>
            </div>
            <div>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">2. How do you determine the value of a property?</h2>
              <p className="mt-3 ">Our team utilizes comprehensive market analysis, considering factors such as location, size, condition, recent sales data, and current market trends to determine the most accurate valuation for your property. </p>

              <h2 className="mt-7 font-bold text-2xl text-primary1 ">3. Do you assist with property financing?</h2>
              <p className="mt-3 ">Yes, we can connect you with trusted mortgage lenders who can help you secure financing for your property purchase. </p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">4. What steps are involved in the home buying/selling process?</h2>
              <p className="mt-3 ">We guide you through every step of the process, from initial consultation to closing. This includes property search, negotiation, home inspections, paperwork, and finalizing the transaction.</p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">5. Do you offer property management services?</h2>
              <p className="mt-3 ">Yes, we offer comprehensive property management services for landlords and investors, including tenant screening, rent collection, maintenance, and more. </p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">6. What sets your real estate company apart from others?</h2>
              <p className="mt-3 ">We pride ourselves on our personalized approach, extensive market knowledge, strong negotiation skills, and commitment to delivering exceptional results for our clients. </p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">7. How can I schedule a consultation with your team?</h2>
              <p className="mt-3 ">You can easily schedule a consultation by contacting us through our website, phone, or email. Our team will be happy to assist you with any questions or concerns you may have.</p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">8. Are there any hidden fees or costs associated with your services?</h2>
              <p className="mt-3 ">Transparency is key to our business model. We provide clear, upfront information about all fees and costs associated with our services, ensuring there are no surprises along the way. </p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">9. Can you assist with relocation services?</h2>
              <p className="mt-3 ">Absolutely! Whether you're moving across town or across the country, our experienced agents can help you find the perfect property to meet your needs and facilitate a smooth transition. </p>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">10. What should I do if I'm interested in listing my property with your company?</h2>
              <p className="mt-3 ">Simply reach out to us to schedule a complimentary listing consultation. We'll assess your property, discuss your goals, and develop a customized marketing strategy to help you achieve the best possible outcome. </p>

              {/* content ended */}
            </div>
          </div>
          {/* <img
                
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&"className="aspect-[1.38] object-contain object-center w-full overflow-hidden max-w-[221px]"
                /> */}
        </div>
      </div>
    </div>
  );
};

export default Faqs;

import React, { useEffect } from 'react';
import TitleSell from '../sell/TitleSell';
import FormLeads from './FormLeads';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../sell/sell.css';

const Leads = () => {
  const phoneNumber = '+12063384663';
  const message = 'Hello, I am interested in your services!';
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly
    });
  }, []);

  return (
    <div className="sell-container relative">
      <TitleSell />
      <FormLeads />
      <div className="whatsapp-icon fixed bottom-10 right-10 bg-green-500 text-white p-3 rounded-full  cursor-pointer animate-vibrate">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          {' '}
          <FontAwesomeIcon icon={faWhatsapp} className="text-lg" />{' '}
        </a>
      </div>
    </div>
  );
};

export default Leads;

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div>
      <div className="bg-slate-50 flex flex-col justify-center items-center px-16 max-md:px-5">
        <div className="flex w-full max-w-[1170px] flex-col-reverse items-stretch mt-3 mb-2.5 max-md:max-w-full">
          <div className="justify-between  items-stretch flex w-full gap-5 max-md:max-w-full max-md:flex-wrap">
            <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e0ca25945799af55bce396a53e8706abf80d178742f516da45ec2a326db36657?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-[1.33] object-contain object-center mt-5 w-16 overflow-hidden shrink-0 max-w-full" alt="dv" />

            <div className="flex justify-center font-custom text-center max-sm:ml-12 items-center flex-col max-sm:order-last">
              <p>
                {' '}
                <br />
                &copy; {new Date().getFullYear()} All rights reserved By DVHomes
                <br />
                <span id="displayYear"></span> Powered By
                <a href="https://codepublicist.com/" target="_blank" rel="noreferrer">
                  {' '}
                  Codepublicist{' '}
                </a>
              </p>
            </div>
            <div className="items-stretch flex gap-4 md:mt-9 self-end">
              <div className="text-slate-900 text-lg font-semibold leading-6font-custom grow whitespace-nowrap">Follow us</div>
              <div className="items-stretch flex gap-2.5">
                <a target="_blank" href=" https://www.facebook.com/dreamvisionhomes/">
                  {' '}
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/45c5c03009ede546d8d454c9009c26cf1aa7c2b69b0daa35571c716a395b2d13?" className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full" alt="dv" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dreamvision.home?igsh=a3h2cTE3ZjhqdjZr">
                  {' '}
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7b0a05f428071f3e4b3bdf6f57830cc22a193a2dc4d624d572693469ce34b07?" className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full" alt="dv" />
                </a>
                <a href="/">
                  {' '}
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0966e335310b947a675b959d2be228778115907185688093741b36658a263126?" className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full" alt="dv" />
                </a>
                <a href="/">
                  {' '}
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f553bccf119739974babf0d639b07f210567658c1d69bd3f124401082eb4d98?" className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full" alt="dv" />
                </a>
              </div>
            </div>
          </div>
          <div className="bg-slate-300 flex shrink-0 h-px flex-col mt-6 max-md:max-w-full " />

          <div className="justify-between items-stretch flex w-full gap-5 mt-8 max-md:max-w-full max-md:flex-wrap">
            <div className="max-md:max-w-full">
              <div className="gap-7 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col items-stretch w-[29%] ml-5 max-md:w-full max-md:ml-0">
                  <div className="items-stretch flex grow flex-col max-md:mt-10">
                    <div className="text-slate-900 font-custom text-lg font-semibold leading-6 whitespace-nowrap">Quick Links</div>

                    {/* admin login added */}
                    <div className="text-gray-600 font-custom text-base leading-6 whitespace-nowrap mt-3.5">
                      <a href="https://dv-homes-front.vercel.app"> Admin Login</a>
                    </div>
                    <div className="text-gray-600 text-base font-custom leading-6 whitespace-nowrap mt-2.5">
                      <a href="https://dvhomes.idxbroker.com/idx/userlogin"> User Login</a>
                    </div>
                    <div className="text-gray-600 text-base font-custom leading-6 whitespace-nowrap mt-2.5">
                      <Link to="/faqs" onClick={scrollToTop}>
                        FAQs
                      </Link>
                    </div>
                    {/* <div className="text-gray-600 text-base leading-6 mt-2.5">
                  <a href='link to page '> FAQs</a> 
                  </div> */}
                  </div>
                </div>
                <div className="flex flex-col items-stretch w-[22%] ml-5 max-md:w-full max-md:ml-0">
                  <div className="items-stretch flex flex-col max-md:mt-10">
                    <div className="text-slate-900 font-custom text-lg font-semibold leading-6 whitespace-nowrap">Discover</div>
                    <div className="text-gray-600 text-base leading-6 font-custom whitespace-nowrap mt-4">
                      <a href="https://dvhomes.idxbroker.com/i/seattle "> Seattle </a>
                    </div>
                    <div className="text-gray-600 text-base leading-6 font-custom whitespace-nowrap mt-2.5">
                      <a href="https://dvhomes.idxbroker.com/i/bothell ">Bothell </a>
                    </div>
                    <div className="text-gray-600 text-base leading-6 font-custom whitespace-nowrap mt-2.5">
                      <a href="https://dvhomes.idxbroker.com/i/bellevue ">Bellevue </a>
                    </div>
                    <div className="text-gray-600 text-base leading-6 font-custom whitespace-nowrap mt-2.5">
                      <a href="https://dvhomes.idxbroker.com/i/renton "> Renton </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-stretch w-[49%] max-md:w-full max-md:ml-0">
                  <div className="items-stretch flex flex-col max-md:mt-10">
                    <div className="text-slate-900 text-lg font-custom font-semibold leading-6 whitespace-nowrap">Other Links</div>
                    <div className="text-gray-600 text-base font-custom  leading-6 whitespace-nowrap mt-4">
                      <Link to="/terms" onClick={scrollToTop}>
                        Terms of Use
                      </Link>
                    </div>
                    <div className="text-gray-600 text-base font-custom leading-6 whitespace-nowrap mt-2.5">
                      <Link to="/privacy" onClick={scrollToTop}>
                        {' '}
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="text-gray-600 text-base font-custom leading-6 mt-2.5">
                      <Link to="/accessibility" onClick={scrollToTop}>
                        {' '}
                        Accessibility
                      </Link>
                    </div>
                    <div className="text-gray-600 text-base font-custom leading-6 mt-2.5">
                      <Link to="/dmca" onClick={scrollToTop}>
                        {' '}
                        DMCA Notice
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-center items-stretch flex flex-col self-start">
              <div className="text-slate-900 text-lg font-custom font-semibold leading-6">Customer Care</div>
              <div className="text-slate-500 font-custom  text-base leading-6 mt-1">
                <a href="tel:2063384663"> 206-338-4663 </a>
              </div>
              <div className="text-slate-900 font-custom  text-lg font-semibold leading-6 mt-5">Need Support?</div>
              <div className="text-slate-500 font-custom  text-base leading-6 mt-1">
                <a href="mailto:team@dreamvisionhomes.com"> team@dreamvisionhomes.com </a>
              </div>
              <div className="text-slate-900 font-custom  text-lg font-semibold leading-6 mt-5">Office Address</div>
              <div className="text-slate-500 text-base leading-6 whitespace-nowrap font-custom  mt-1">
                <a href="https://maps.app.goo.gl/VYdHMD6NEreRuvP26">26943 168th Pl SE, Covington, WA 98042 </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

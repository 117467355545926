import React, { useState, useEffect } from 'react';
import { dummy } from './dummydata';

const Privacy = () => {
  const [toggle, setToggle] = useState(false);
  const [index, setIndex] = useState();
  const ArrowClick = (id) => {
    setIndex(id);
    setToggle(!toggle);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the FeedBack component mounts
    });
  }, []);
  return (
    <div>
      <div className="flex justify-center items-center mb-32">
        <div className="shadow-lg bg-slate-50 justify-between items-stretch max-w-[1170px] rounded-xl mt-40 flex w-full gap-5 px-10 py-9 max-md:max-w-full max-md:flex-wrap max-md:px-5">
          <div className="flex flex-col items-stretch max-md:max-w-full">
            <div className="text-slate-500 text-lg leading-5 max-md:max-w-full">Privacy Policy</div>
            <div className="text-primary1 text-3xl font-bold leading-[49.92px] mt-6 max-md:max-w-full max-md:text-4xl">JOHN L. SCOTT REAL ESTATE PRIVACY POLICY</div>
            <div className="text-slate-900 text-lg leading-8 mt-3 max-md:max-w-full">
              {/* Opening Doors to your Dream.
                        <br /> */}
              Let’s Make it Reality!
            </div>
            <div>
              <p className="mt-5 leading-6">John L. Scott Real Estate respects your privacy. This Privacy Policy (this “Policy”) applies to all information learned about or obtained from you when you visit the John L. Scott Real Estate web site at http://www.johnlscott.com/ or any of its subdomains (the “Site”), whether you access the Site through a personal computer, mobile device or any other means. The Site is provided by John L. Scott, Inc. (“John L. Scott”). By accessing or using the Site, you consent to the Policy and John L. Scott’s collection, use and disclosure of your personal information as described below. Please read this Policy carefully. John L. Scott may amend and/or modify this Policy from time to time and without notice, effective upon posting on the Site. Your continued access and use of the Site after the Policy has been modified constitutes your consent to the Policy as modified.</p>
            </div>
            <div>
              <h2 className="mt-7 font-bold text-2xl text-primary1 ">Information We Collect</h2>
              <p className="mt-3 ">Information You Give Us: John L. Scott receives and stores any information you post on or provide through the Site, via Property Tracker® and/or Market Watch, or through any other means. This includes any personal information you provide, such as your name, mailing address, email address and telephone number. You can choose not to provide such information, in which case you will not be able to access or use portions of the Site. John L. Scott may, but is not obligated to, retain the content of any emails you send or information you provide by any other means.</p>
              <p className="mt-3">Automated Collection: John L. Scott automatically receives and stores certain types of information when you visit the Site, such as the name of the domain and host from which you access the Internet; the IP address of the computer you are using and the browser and operating system you are using; the date and time you access the Site, the Internet address of the website from which you linked to the Site and any search terms you used to find the Site. We use “cookies” to automatically gather some of this information. A cookie is a small text file that the Site sends to your computer to collect information about your activities on the Site. The cookie transmits this information back to the Site each time your browser requests a page from the Site, and the information is stored to help facilitate your use of the Site the next time you visit. You can set your browser to not accept cookies, but then you may not be able to access or use certain portions of the Sites.</p>

              {dummy.map((data) => (
                <div id={data.id}>
                  <div className="max-sm:ml-[3%] items-stretch bg-stone-50 flex flex-col justify-center mt-6 rounded-xl max-md:max-w-full">
                    <div className="items-start px-4 py-6 max-md:max-w-full">
                      {/* first  started */}

                      <div>
                        <div className="items-stretch self-stretch flex justify-between gap-2.5 mt-4 max-md:max-w-full max-md:flex-wrap">
                          <div className="text-slate-900 text-lg font-semibold leading-6 self-stretch max-md:max-w-full">{data.head}</div>
                          <img onClick={() => ArrowClick(data.id)} loading="lazy" alt="img" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c1995d29c1751a39a02ca10faa0a8b2a20f89de7fc2162d7adfb26ebceff8b?" className="hover:cursor-pointer aspect-square object-contain object-center w-[18px] overflow-hidden self-center shrink-0 max-w-full my-auto" />
                        </div>
                        <div className="items-stretch self-stretch flex w-full justify-between gap-5 mt-1.5 pr-20 max-md:max-w-full max-md:flex-wrap max-md:pr-5"></div>
                        {/* first  ended */}
                        <div className="self-stretch bg-slate-300 flex shrink-0 h-px flex-col mt-4 max-md:max-w-full" />
                        {/* content started */}
                        {data.id === index && toggle && (
                          <div className="justify-center items-center self-stretch bg-white flex flex-col mt-6 rounded-lg max-md:max-w-full max-md:px-5">
                            <p>{data.para}</p>

                            {data.data ? (
                              <ul className="list-disc">
                                <li>{data.data}</li>
                              </ul>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>

                      {/* content ended */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <img
                
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&"className="aspect-[1.38] object-contain object-center w-full overflow-hidden max-w-[221px]"
                /> */}
        </div>
      </div>
    </div>
  );
};

export default Privacy;

import { Link } from 'react-router-dom';
import React from 'react';

const FindDv = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="header bg-slate-50 self-center w-full max-w-[1170px] mt-14 pt-6 pb-9 px-10 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[74%] max-md:w-full max-md:ml-0">
            <div className="items-stretch flex flex-col my-auto max-md:max-w-full max-md:mt-10">
              <div className="text-primary1 text-4xl font-bold leading-10 self-stretch max-md:max-w-full">
                Let us help you find <br />
                your dream Home.
              </div>
              <div className="self-stretch text-slate-500 text-base leading-6 mt-4 max-md:max-w-full">Navigate your dream home effortlessly.</div>
              <Link to="/contact">
                <button className="justify-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 flex w-40 max-w-full flex-col mt-9 px-6 py-4 rounded-[32px] max-md:px-5">
                  <div className="items-stretch flex justify-between gap-1.5 py-0.5">
                    <div className="text-zinc-50 text-base leading-6 grow whitespace-nowrap">Learn more</div>
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/26f85190dfee1f34ff37c3d109aa3de5dd96117f9c45e0c1a573b67ea4c5d5ad?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-4 overflow-hidden self-center shrink-0 max-w-full my-auto" alt="dv" />
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[48%] ml-5 max-md:w-full max-md:ml-0">
            <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/56e8a7ead3a0dda4b2c890f943326cec6e9e0f567b2218d97436c1980225aea3?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-[1.45] object-contain object-center w-full self-stretch overflow-hidden grow max-md:max-w-full max-md:mt-10" alt="dv" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FindDv;

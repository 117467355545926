import React from 'react';

const Title = () => {
  return (
    <form className="flex justify-center items-center">
      <header className="header bg-slate-50 self-center w-full max-w-[1170px] mt-32 pt-6 pb-9 px-10 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5">
        <section className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[74%] max-md:w-full max-md:ml-0">
            <div className="items-stretch flex flex-col my-auto max-md:max-w-full max-md:mt-10">
              <h1 className="text-primary1 text-5xl font-bold leading-[56px] max-md:max-w-full max-md:text-4xl max-md:leading-[52px]" aria-label="Start Listing your Properties with DV Homes">
                Start Listing your Properties
                <br /> with DV Homes
              </h1>
              <p className="text-slate-900 text-lg leading-8 mt-3 max-md:max-w-full" aria-label="Connect with us today and let's start turning your real estate aspirations into reality!">
                Connect with us today and let's start turning your
                <br /> real estate aspirations into reality!
              </p>
            </div>
          </div>{' '}
          <div className="flex flex-col items-stretch w-[26%] ml-5 max-md:w-full max-md:ml-0">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e66cd13722c0bc1f7ffa0513fa93151d09599003e04f9b5bf7c3ef15d9ae81f1?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-[1.18] object-contain object-center w-[259px] overflow-hidden shrink-0 max-w-full grow max-md:mt-10" alt="Property" aria-label="Property Image" />
          </div>
        </section>
      </header>
    </form>
  );
};
export default Title;

import React, { useEffect } from 'react';
import TitleAbout from './TitleAbout';
import Profile from './Profile';
import Data from './Data';
import HelpDv from './HelpDv';
import FindDv from './FindDv';

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the About component mounts
    });
  }, []);

  return (
    <div>
      <TitleAbout />
      <Profile />
      <Data />
      <HelpDv />
      <FindDv />
    </div>
  );
};

export default About;

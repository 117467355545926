import * as React from 'react';
import { Link } from 'react-router-dom';
const Interior = () => {
  return (
    <div className="flex-col items-stretch overflow-hidden relative flex min-h-[344px] px-16 py-12 max-md:px-5">
      <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd30a5866719786006fa99b0388bf78f23896399e0323734e939a6b46c1287f4?apiKey=60bd44a890ac4356aa91264734464f16&" className="absolute h-full w-full object-cover object-center inset-0" alt="dv" />
      <div className="relative justify-center items-center flex flex-col mt-2.5 px-16 max-md:max-w-full max-md:px-5">
        <div className="flex w-[491px] max-w-full flex-col items-stretch">
          <div className="text-zinc-50 text-center text-4xl font-bold leading-10 max-md:max-w-full">
            Start Listing or Buying
            <br />a property with DV Homes.
          </div>
          <div className="text-zinc-50 text-center text-base leading-6 self-center mt-4 max-md:max-w-full">Talk to our experts or Browse through more properties.</div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link to="/sell">
          <button className="relative justify-center items-stretch bg-primary1 transition duration-500 hover:bg-secondary1 self-center flex w-[180px] max-w-full flex-col mt-9 mb-2.5 px-9 py-4 rounded-[32px] max-md:px-5">
            <div className="items-stretch flex justify-between gap-1.5 py-0.5">
              <div className="text-zinc-50 text-base leading-6 grow whitespace-nowrap">Get started</div>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b2d0f2b8a1bc4c356b61585b3d671fff82017c046a9d4b68005b3b3974bf186d?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-4 overflow-hidden self-center shrink-0 max-w-full my-auto" alt="dv" />
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Interior;

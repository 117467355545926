import axios from 'axios';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const FormLeads = () => {
  const showAlert = () => {
    Swal.fire({
      title: 'SUCCESS',
      text: 'Message sent successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    date: '',
    category: '',
    daysCategory: '',
    address: '',
    message: '',
  });

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    address: '',
    message: '',
  });

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDaysCategory, setSelectedDaysCategory] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bathrooms, setBathrooms] = useState(1);
  const [bedrooms, setBedrooms] = useState(1);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      firstname: '',
      lastname: '',
      number: '',
      email: '',
      date: '',
      category: '',
      daysCategory: '',
      address: '',
      message: '',
    };

    const nameRegex = /^[a-zA-Z]{3,}$/;
    if (!formData.firstname.trim() || !nameRegex.test(formData.firstname.trim())) {
      newErrors.firstname = 'First name is required';
      isValid = false;
    }

    if (!formData.lastname.trim()) {
      newErrors.lastname = 'Last name is required';
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      newErrors.email = 'Enter a valid Email address ';
      isValid = false;
    }

    if (!selectedDate) {
      newErrors.date = 'Date is required';
      isValid = false;
    }

    if (!selectedCategory) {
      newErrors.category = 'Category is required';
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleIncrement = (type) => {
    if (type === 'bathrooms') {
      setBathrooms(bathrooms + 1);
    } else if (type === 'bedrooms') {
      setBedrooms(bedrooms + 1);
    }
  };

  const handleDecrement = (type) => {
    if (type === 'bathrooms' && bathrooms > 1) {
      setBathrooms(bathrooms - 1);
    } else if (type === 'bedrooms' && bedrooms > 1) {
      setBedrooms(bedrooms - 1);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      phone: formData.number,
      email: formData.email,
      address: formData.address,
      message: formData.message,
      category: selectedCategory,
      duration: selectedDaysCategory,
      date: selectedDate,
      bathrooms: bathrooms,
      bedrooms: bedrooms,
    };

    console.log('Form data being submitted:', data);

    try {
      await axios.post('https://dv-homes-front.vercel.app/api/leads', data);

      showAlert();

      setFormData({
        firstname: '',
        lastname: '',
        number: '',
        email: '',
        address: '',
        message: '',
      });

      setSelectedCategory('');
      setSelectedDaysCategory('');
      setSelectedDate(new Date());
      setBathrooms(1);
      setBedrooms(1);
    } catch (error) {
      console.error('Error submitting form:', error);
      Swal.fire({
        title: 'ERROR',
        text: 'There was an issue submitting the form. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="items-start self-center bg-stone-50 flex w-full max-w-[1170px] flex-col mt-10 mb-10 p-10 rounded-xl max-md:max-w-full max-md:px-5">
        <div className="text-slate-900 text-2xl font-bold leading-9 self-start">Connect with dream team.</div>
        <div className="text-slate-500 text-lg leading-6 whitespace-nowrap mt-2 self-start">Fill out the form, and we'll be in touch soon!</div>
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="self-stretch bg-slate-300 flex shrink-0 h-px flex-col mt-6 max-md:max-w-full" />

          <div className="items-stretch self-stretch flex justify-between gap-4 mt-6 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label htmlFor="firstname" className="">
                  First name
                </label>
              </div>
              <input type="text" id="firstname" name="firstname" value={formData.firstname} onChange={handleInputChange} placeholder="Enter your first name" className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5" />
              <div className="text-rose-800">{error.firstname}</div>
            </div>
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label htmlFor="lastname" className="">
                  Last name
                </label>
              </div>
              <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleInputChange} placeholder="Enter your last name" className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5" />
              <div className="text-rose-800">{error.lastname}</div>
            </div>
          </div>

          <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label htmlFor="number" className="">
                  Phone number
                </label>
              </div>
              <input type="tel" id="number" pattern="[0-9]{10}" name="number" value={formData.number} onChange={handleInputChange} placeholder="Enter your Mobile number" className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5" />
              <div className="text-rose-800">{error.number}</div>
            </div>
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label htmlFor="email" className="">
                  Email
                </label>
              </div>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter your email address" className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 mt-2.5 pl-4 pr-16 py-4 rounded-lg items-start max-md:max-w-full max-md:pr-5" />
              <div className="text-rose-800">{error.email}</div>
            </div>
          </div>

          <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <div className="flex flex-col">
                  <span className="text-slate-900 text-base leading-6 mb-1">Select category</span>
                  <div className="flex gap-2.5 mt-2.5 py-1 self-start">
                    <label className={`category-button ${selectedCategory === 'buy' ? 'bg-secondary1 text-zinc-50' : 'bg-white text-slate-900'} text-base leading-6 whitespace-nowrap justify-center items-stretch border transition duration-500 hover:bg-slate-200 border-slate-400 hover:border-zinc-950 grow px-6 py-3.5 rounded-full max-md:px-5`}>
                      <input type="radio" name="category" value="buy" onChange={() => setSelectedCategory('buy')} className="hidden" />
                      Buy
                    </label>
                    <label className={`category-button ${selectedCategory === 'sell' ? 'bg-secondary1 text-zinc-50' : 'bg-white text-slate-900'} text-base leading-6 whitespace-nowrap justify-center border border-slate-400 transition duration-500 hover:bg-slate-200 hover:border-zinc-950 items-stretch grow px-6 py-3.5 rounded-full max-md:px-5`}>
                      <input type="radio" name="category" value="sell" onChange={() => setSelectedCategory('sell')} className="hidden" />
                      Sell
                    </label>
                  </div>
                  <div className="text-rose-800">{error.category}</div>
                </div>
              </div>
            </div>
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <span htmlFor="daysCategory" class="relative">
                  Select duration
                  <div class="inline-block relative group">
                    <sup class="text-rose-700 text-sm font-extrabold w-10 h-10 cursor-pointer">*</sup>
                    <div class="hidden absolute bg-slate-300 text-white text-lg rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 mb-1 group-hover:block  whitespace-nowrap">Buy / Sell within</div>
                  </div>
                </span>
              </div>
              <div className="bg-white w-full flex gap-2.5 mt-2.5 rounded-lg items-start text-center max-md:max-w-full max-md:flex-wrap ">
                <label className={`category-button ${selectedDaysCategory === '1-3 months' ? 'bg-secondary1 text-zinc-50' : 'bg-white text-slate-900'} text-base leading-6 whitespace-nowrap justify-center items-stretch border transition duration-500 hover:bg-slate-200 border-slate-400 hover:border-zinc-950 grow px-6 py-3.5 rounded-full max-md:px-5`}>
                  <input type="radio" name="daysCategory" value="1-3 months" onChange={() => setSelectedDaysCategory('1-3 months')} className="hidden" />
                  1-3 months
                </label>
                <label className={`category-button ${selectedDaysCategory === '3-6 months' ? 'bg-secondary1 text-zinc-50' : 'bg-white text-slate-900'} text-base leading-6 whitespace-nowrap justify-center border border-slate-400 transition duration-500 hover:bg-slate-200 hover:border-zinc-950 items-stretch grow px-6 py-3.5 rounded-full max-md:px-5`}>
                  <input type="radio" name="daysCategory" value="3-6 months" onChange={() => setSelectedDaysCategory('3-6 months')} className="hidden" />
                  3-6 months
                </label>
                <label className={`category-button ${selectedDaysCategory === '6+ months' ? 'bg-secondary1 text-zinc-50' : 'bg-white text-slate-900'} text-base leading-6 whitespace-nowrap justify-center items-stretch border transition duration-500 hover:bg-slate-200 border-slate-400 hover:border-zinc-950 grow px-6 py-3.5 rounded-full max-md:px-5`}>
                  <input type="radio" name="daysCategory" value="6+ months" onChange={() => setSelectedDaysCategory('6+ months')} className="hidden" />
                  6+ months
                </label>
              </div>
              <div className="text-rose-800">{error.daysCategory}</div>
            </div>
          </div>

          <div className="items-stretch self-stretch flex justify-between gap-4 mt-4 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label htmlFor="bathrooms" className="">
                  Number of Bathrooms
                </label>
              </div>
              <div className="flex items-center mt-2.5">
                <button type="button" onClick={() => handleDecrement('bathrooms')} className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 px-3 py-2 rounded-lg">
                  -
                </button>
                <span className="mx-3">{bathrooms}</span>
                <button type="button" onClick={() => handleIncrement('bathrooms')} className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 px-3 py-2 rounded-lg">
                  +
                </button>
              </div>
            </div>
            <div className="items-stretch flex grow basis-[0%] flex-col max-md:max-w-full">
              <div className="text-red-500 text-base leading-6 max-md:max-w-full">
                <label htmlFor="bedrooms" className="">
                  Number of Bedrooms
                </label>
              </div>
              <div className="flex items-center mt-2.5">
                <button type="button" onClick={() => handleDecrement('bedrooms')} className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 px-3 py-2 rounded-lg">
                  -
                </button>
                <span className="mx-3">{bedrooms}</span>
                <button type="button" onClick={() => handleIncrement('bedrooms')} className="text-base leading-6 whitespace-nowrap justify-center border border-slate-400 px-3 py-2 rounded-lg">
                  +
                </button>
              </div>
            </div>
          </div>

          <div className="mb-6 text-red-500 text-base leading-6 self-stretch mt-4 max-md:max-w-full">
            <label htmlFor="address" className="">
              Property Address
            </label>
          </div>
          <textarea id="address" name="address" value={formData.address} onChange={handleInputChange} placeholder="Enter your current address" className="mt-1 p-3 w-full md:max-w-full border border-slate-400 rounded-md"></textarea>
          <div className="text-rose-800">{error.address}</div>

          <div className="mb-6">
            <label htmlFor="message" className="block text-slate-900 text-base leading-6">
              Message
            </label>
            <textarea id="message" name="message" value={formData.message} onChange={handleInputChange} placeholder="Enter your message" className="mt-6 p-3 w-full md:max-w-full border border-slate-400 rounded-md"></textarea>
            <div className="text-rose-800">{error.message}</div>
          </div>

          <button className="text-zinc-50 text-base leading-6 whitespace-nowrap justify-center transition duration-500 bg-primary1 hover:bg-secondary1 mt-6 px-4 py-3.5 rounded-[45px] self-start max-md:px-5">Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default FormLeads;

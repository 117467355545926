import React from 'react';
import Slider from 'react-slick';
import LeftArrow from '../../../src/assets/left-arrow.svg';
import RightArrow from '../../../src/assets/right-arrow.svg';

const exploreproperty = [
  {
    id: 1,
    url: 'https://dvhomes.idxbroker.com/i/seattle',
    img: 'seattle.jpg',
    title: 'Seattle',
  },
  {
    id: 2,
    url: 'https://dvhomes.idxbroker.com/i/bellevue',
    img: 'belle.jpg',
    title: 'Bellevue',
  },
  {
    id: 3,
    url: 'https://dvhomes.idxbroker.com/i/bothell',
    img: 'bothell.jpg',
    title: 'Bothell',
  },
  {
    id: 4,
    url: 'https://dvhomes.idxbroker.com/i/sammamish',
    img: 'sammamish.jpeg',
    title: 'Sammamish',
  },
  {
    id: 5,
    url: 'https://dvhomes.idxbroker.com/i/kent',
    img: 'kent.jpg',
    title: 'Kent',
  },
  {
    id: 6,
    url: 'https://dvhomes.idxbroker.com/i/renton',
    img: 'Renton.jpg',
    title: 'Renton',
  },
  {
    id: 7,
    url: 'https://dvhomes.idxbroker.com/i/fedralway',
    img: 'fedralway.jpg',
    title: 'Federal Way',
  },
  {
    id: 8,
    url: 'https://dvhomes.idxbroker.com/i/tacoma',
    img: 'tacoma.png',
    title: 'Tacoma',
  },
];

const ExploreProperty = () => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => <img src={LeftArrow} alt="prevArrow" {...props} />;

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => <img src={RightArrow} alt="nextArrow" {...props} />;

  const settings = {
    // autoplay : true,
    loop: true,
    // dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center items-center">
      <div className="header bg-slate-50 self-center w-full max-w-[1170px] mt-14 pt-6 mb-6 pb-9 px-10 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5">
        <div className="items-stretch self-stretch flex flex-col">
          <div className="justify-between items-stretch flex w-full gap-5 max-md:max-w-full max-md:flex-wrap">
            <div className="justify-center items-stretch flex flex-col px-5 max-md:max-w-full">
              <div className="text-slate-900 text-3xl font-bold leading-10 max-md:max-w-full">Explore Properties by Area</div>
              <div className="text-slate-500 text-lg leading-6 mt-3 max-md:max-w-full">Navigate your dream home effortlessly - Explore properties by city with DV Homes.</div>
            </div>
            {/* <div className="justify-end items-stretch flex gap-1.5 px-5 py-px self-start">
          <a href='link to the page' className="text-slate-900 text-lg font-medium leading-6 grow whitespace-nowrap">
            See all cities
          </a>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/27200df0717a06e391f2af58854e1cb386434932d409e0cd9ee66c7b24399224?"
            className="aspect-square object-contain object-center w-[18px] overflow-hidden self-center shrink-0 max-w-full my-auto"
          />
        </div> */}
          </div>
          <div className="w-full mt-12 px-5  max-md:max-w-full max-md:mt-10">
            <div className="gap-5 flex justify-center  items-center max-md:flex-col max-md:items-stretch max-md:gap-0">
              <div className="flex flex-col  items-stretch w-full max-md:w-full max-md:ml-0">
                {/* <Carousel slides={slides} /> */}

                <Slider {...settings} className="">
                  {exploreproperty.map((data) => (
                    <div key={data.id} className="my-slide  flex flex-col rounded-lg items-stretch w-48 h-40 max-md:w-full sm:h-[411px] max-sm:h-[431px] md:h-full max-md:ml-0">
                      <a href={data.url} className="flex-col justify-between rounded-lg items-stretch self-stretch overflow-hidden relative flex aspect-[0.7596685082872928] grow px-6 py-7 ml-4 max-md:mt-6 max-md:px-5">
                        <img loading="lazy" srcSet={`./img/area/${data.img}`} className="absolute h-full w-full object-cover object-center brightness-75 opacity-100 inset-0" alt="dv" />
                        <div className="relative text-center rounded-lg text-zinc-100  text-lg leading-5">{data.title}</div>

                        <div className="relative items-stretch flex justify-between gap-1.5 mt-60 pr-8 py-px max-md:mt-10 max-md:pr-5">
                          <button className="text-transparent text-base font-semibold leading-6">Explore</button>
                        </div>
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreProperty;

import React, { useEffect } from 'react';
import PeopleLiving from './PeopleLiving';
import ImageSlider from './ImageSlider';
import ExploreProperty from './ExploreProperty';
import HelpDv from '../about/HelpDv';
import Interior from './Interior';
import PropertyType from './PropertyType';
import FindDv from '../about/FindDv';
import Data from '../about/Data';
import LandImage from './LandImage';
import { Fade } from 'react-awesome-reveal';

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the Home component mounts
    });
  }, []);

  return (
    <Fade className="">
      <LandImage />
      <HelpDv />
      <ExploreProperty />
      <FindDv />
      <PeopleLiving />
      <Data />
      <PropertyType />
      <Interior />
    </Fade>
  );
};

export default Home;

// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import Preloading from './components/Preloading';
import Header from './components/Header';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/about/About';
import Sell from './components/sell/Sell';
import Contact from './components/contact/Contact';
import Footer from './components/Footer';

import Privacy from './components/PrivacyPolicy/Privacy';
import TermsofUse from './components/PrivacyPolicy/TermsofUse';
import Access from './components/PrivacyPolicy/Access';
import Dmca from './components/PrivacyPolicy/Dmca';
import Faqs from './components/PrivacyPolicy/Faqs';
import FeedBack from './components/feedBack/FeedBack';
import Cookies from 'js-cookie'; // Import js-cookie library
import Leads from './components/leads/Leads';
import AboutYou from './components/aboutyou/AboutYou';
import LakeChelan from './components/LakeChelan/lakechelan.tsx'
import Testimonials from './components/testimonials/Testimonials.js';
import Calendar from './components/calendar/Calendar.js';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const preloadingCompleted = Cookies.get('preloadingCompleted'); // Check if preloading has been completed before

    if (preloadingCompleted) {
      setIsLoading(false); // Skip preloading if it has been completed before
    } else {
      // Simulate a delay for loading main content
      const timer = setTimeout(() => {
        setIsLoading(false);
        Cookies.set('preloadingCompleted', true); // Set a cookie to indicate that preloading has been completed
      }, 3000); // Adjust the delay as needed

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Preloading />
      ) : (
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
      )}
    </div>
  );
}

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/sell',
        element: <Sell />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/privacy',
        element: <Privacy />,
      },
      {
        path: '/terms',
        element: <TermsofUse />,
      },
      {
        path: '/accessibility',
        element: <Access />,
      },
      {
        path: '/dmca',
        element: <Dmca />,
      },
      {
        path: '/leads',
        element: <Leads />,
      },
      {
        path: '/faqs',
        element: <Faqs />,
      },
      {
        path: '/feedback',
        element: <FeedBack />,
      },
      {
        path: '/aboutyou',
        element: <AboutYou />,
      },
      {
        path: '/lakechelan',
        element: <LakeChelan />,
      },
      {
        path: '/testimonials',
        element: <Testimonials />,
      },
      {
        path: '/calendar',
        element: <Calendar />,
      },
    ],
  },
]);

export default App;

import React from 'react';
import { useEffect } from 'react';
import FormAbout from './FormAbout';
function AboutYou() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the Contact component mounts
    });
  }, []);
  return (
    <div>
      <FormAbout />
    </div>
  );
}

export default AboutYou;

export const dummy = [
  {
    id: 1,
    head: 'Use of Personal Information',
    para: 'John L. Scott may use the personally identifiable information provided or obtained through the Site to:',
    data: ['In connection with the creation and maintenance of your Property Tracker® and/or Market Watch account(s)', 'In connection with the delivery and development of Property Tracker® email alerts and other email communications', 'Perform research and analysis about your use of, or interest in, our products, services or content, or products, services or content offered by John L. Scott or others', 'Communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you either from us or other third parties', 'Tailor your user experience', 'Develop and display third-party content and advertising tailored to your interests on the Site and other sites', 'Enforce our Terms and Conditions', 'Manage our business, and', 'Perform functions as otherwise described to you at the time of collection.'],
  },
  {
    id: 2,
    head: 'Children',
    para: 'The Site is not directed at children, and John L. Scott does not knowingly collect personal information directly from users or from other web sites or services directed at children. Consistent with the Federal Children’s Online Privacy Protection Act of 1998 (COPPA), John L. Scott will not knowingly request or collect personally identifiable information from any child under age 13 without requiring parental consent. Any person who provides his or her personal information to use through the Site represents that he or she is older than 12 years of age.',
  },
  {
    id: 3,
    head: 'Corrections and Updates',
    para: 'If you want to view, delete or modify your personal information, you may do so by sending an email to [contactus@johnlscott.com]. You may also choose to opt-out of receiving future Property Tracker® email alerts or other emails by following the unsubscribe instructions included in email communications, or by contacting John L. Scott as provided above. John L. Scott reserves the right to verify the identity of any person making a request to opt-out or to delete or modify personal information; provided, however, that we will have no liability of any kind resulting from false or erroneous requests or any change or deletion made by John L. Scott for any reason. Personal information collected and transferred to back-up or archival storage may be retained indefinitely. John L. Scott may also retain unmodified information as necessary for business records and as required under applicable law.',
  },
  {
    id: 4,
    head: 'Third Party Sites',
    para: 'The Site may contain links to other websites. John L. Scott does not control, and makes no representations whatsoever regarding, such other websites or the products and services offered by or through websites accessed through links on the Site, even those with which John L. Scott may have an affiliation. If you decide to access third party websites linked through the Site you do so at your own risk. You should carefully review the privacy policy and terms of any such website.',
  },
  {
    id: 5,
    head: 'What Steps Do We Take To Protect Your Information Online?',
    para: 'We take reasonable measures to protect your personal information in an effort to prevent loss, misuse and unauthorized access, disclosure, alteration and destruction. All John L. Scott employees with access to the personal information you provide to us, through the Site, via Property Tracker® and/or MarketInsights® or through any other means, are bound by a confidentiality agreement prohibiting the unauthorized disclosure and/or use of your personal information. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse. If we become aware of a security systems breach, we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice through the Site if a security breach occurs. We may also send an email to you at the email address you have provided to use in these circumstances. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. Users should also be aware of how they handle and disclose their personal information and should avoid sending personal information through insecure email. Please refer to the Federal Trade Commission’s website at http://www.ftc.gov/bcp/menus/consumer/data.shm for information about how to protect yourself against identity theft.',
  },
  {
    id: 6,
    head: 'Your California Privacy Rights',
    para: "Effective January 1, 2005, under California Civil Code Section 1798.83, if an individual who is a California resident has provided personal information to a business in connection with a business relationship that is primarily for personal, family, or household purposes, and if that business has within the immediately preceding calendar year disclosed such an individual's personal information to a third-party and knows or should have known that such third-party used the information for its own direct marketing purposes, then that business is obligated to disclose in writing to such individual upon request, what personal information was shared and with whom it was shared. Any request for a disclosure required under this California law should be sent to us via email at [contactus@johnlscott.com] or via regular mail at: John L. Scott WA Service Center Attn: Legal Department 11040 Main Street Bellevue, WA 98004 Please note that under this law, we are not required to respond to a customer's request more than once in a calendar year, nor are we required to respond to any request that is not sent to the email or mailing address designated above.",
  },
  {
    id: 7,
    head: 'International Visitors',
    para: 'The Site is hosted in and provided from the United States. If you use the Site from the European Union, Canada or other regions with laws governing data collection and use that may differ from U.S. law, please note that you are transferring your personal data to the United States. The United States does not have the same data protection laws as the EU, Canada and some other regions. By providing your personal information, you consent to the transfer of your personal data to the United States and the use of your personal information, in accordance with this Policy.',
  },
  {
    id: 8,
    head: 'Questions?',
    para: 'If you have any questions about this Policy, please contact us at [contactus@johnlscott.com].',
  },
];

import React from 'react';

const PropertyType = () => {
  return (
    <div className="flex justify-center mt-10 mb-10 items-center">
      {/* // <div className="header  self-center w-full max-w-[1170px] mt-14 pt-6 mb-10 pb-9 px-10 rounded-xl max-md:max-w-full max-md:mt-10 max-md:px-5"> */}

      <div className="items-stretch self-stretch flex flex-col">
        <div className="justify-between items-stretch flex w-full gap-5 max-md:max-w-full max-md:flex-wrap">
          <div className="justify-center items-stretch flex flex-col px-5">
            <div className="text-slate-900 text-3xl font-bold leading-10 whitespace-nowrap">Explore Property Types</div>
            <div className="text-slate-500 text-lg leading-6 whitespace-nowrap mt-3">Navigate your dream home effortlessly</div>
          </div>
          <div className="justify-end items-stretch flex gap-1.5 px-5 py-px self-start">
            {/*           
          <a href="link to types " className="hover:text-slate-900  text-slate-500 text-lg font-medium leading-6 grow whitespace-nowrap">
            See all types
          </a>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/27200df0717a06e391f2af58854e1cb386434932d409e0cd9ee66c7b24399224?apiKey=60bd44a890ac4356aa91264734464f16&"
            className="aspect-square object-contain object-center w-[18px] overflow-hidden self-center shrink-0 max-w-full my-auto"
          /> */}
          </div>
        </div>
        <div className="items-stretch flex w-full justify-between gap-5 mt-12 px-5 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
          <a target="_blank" rel="noreferrer" href="https://dvhomes.idxbroker.com/i/houses" className="group justify-center  bg-stone-50 transition duration-500 hover:bg-secondary1 flex grow basis-[0%] flex-col pl-6 pr-20 py-6 rounded-lg items-start max-md:px-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a1f43d7c70da1064d81a772d1a77084e1ef0440b0e40defa675b166c2042b82?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden max-w-full" alt="dv" />
            <div className="text-slate-900 group-hover:text-zinc-50 text-lg font-semibold leading-5 mt-4">Houses</div>
            <div className="text-slate-500 text-base group-hover:text-zinc-50 leading-6 whitespace-nowrap mt-1.5">10 Properties</div>
          </a>
          <a target="_blank" rel="noreferrer" href="https://dvhomes.idxbroker.com/i/land" className="group  hover:bg-secondary1 justify-center transition duration-500 bg-stone-50 flex grow basis-[0%] flex-col pl-6 pr-20 py-6 rounded-lg items-start max-md:px-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/73a6505ccdda18028c882281f4a90f9ec1b0f367f6c0a4a55a3def6fecd12aba?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden max-w-full" alt="dv" />
            <div className="text-slate-900  group-hover:text-zinc-50 text-lg font-semibold leading-5 mt-4">Land</div>
            <div className="text-slate-500  group-hover:text-zinc-50 text-base leading-6 whitespace-nowrap mt-1.5">20 Properties</div>
          </a>
          <a target="_blank" rel="noreferrer" href="https://dvhomes.idxbroker.com/i/commercial" className="group  hover:bg-secondary1 transition duration-500 justify-center bg-stone-50 flex grow basis-[0%] flex-col pl-6 pr-20 py-6 rounded-lg items-start max-md:px-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddf51e29d92cad891c4117f364465d6625dc6091365e74fae1b7b98ceeee9d89?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden max-w-full" alt="dv" />
            <div className="text-slate-900  group-hover:text-zinc-50 text-lg font-semibold leading-5 mt-4">Commercial</div>
            <div className="text-slate-500   group-hover:text-zinc-50 text-base leading-6 whitespace-nowrap mt-1.5">07 Properties</div>
          </a>
          <a target="_blank" rel="noreferrer" href="https://dvhomes.idxbroker.com/i/office" className="group  hover:bg-secondary1 justify-center transition duration-500 bg-stone-50 flex grow basis-[0%] flex-col pl-6 pr-20 py-6 rounded-lg items-start max-md:px-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/78bc29601402973e2bc213a7bbdf3699aa9831ddfcf6a7b5ed8dc01d92ce9b67?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden max-w-full" alt="dv" />
            <div className="text-slate-900  group-hover:text-zinc-50 text-lg font-semibold leading-5 self-stretch whitespace-nowrap mt-4">Business</div>
            <div className="text-slate-500  group-hover:text-zinc-50 text-base leading-6 self-stretch whitespace-nowrap mt-1.5">11 Properties</div>
          </a>
          <a target="_blank" rel="noreferrer" href="https://dvhomes.idxbroker.com/i/lease" className="group  hover:bg-secondary1 justify-center transition duration-500 bg-stone-50 flex grow basis-[0%] flex-col pl-6 pr-20 py-6 rounded-lg items-start max-md:px-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0d47a0b7f5f440349436a977dcd11a10bd23f31330e80dcadd34d613424935c?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden max-w-full" alt="dv" />
            <div className="text-slate-900  group-hover:text-zinc-50 text-lg font-semibold leading-5 mt-4">Lease</div>
            <div className="text-slate-500  group-hover:text-zinc-50 text-base leading-6 whitespace-nowrap mt-1.5">32 Properties</div>
          </a>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default PropertyType;

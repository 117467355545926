import React from 'react';
import ReviewCarousal from './ReviewCarousal';
import { Link } from 'react-router-dom';

const PeopleLiving = () => {
  return (
    <div className="flex w-full max-w-[1170px] flex-col items-stretch mt-10  max-md:max-w-full justify-center mx-auto">
      <div className="text-slate-900 text-3xl font-bold leading-10 text-center max-md:max-w-full">People Love Living With DV Homes</div>
      <div className="text-slate-500 text-lg leading-6 self-center whitespace-nowrap mt-3">Let’s hear what our clients love about us</div>
      <div className="">
        <div className="">
          <ReviewCarousal />
        </div>
        <div className="mb-5 mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/feedback">
            <button className="relative justify-center text-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 self-center flex  max-w-full flex-col mt-10 mb-4 px-9 py-4 rounded-[32px] max-md:px-5">
              <div className="items-stretch flex justify-between gap-1.5 py-0.5">
                <div className="text-zinc-50 text-center text-base leading-6 grow whitespace-nowrap">Submit Feedback</div>
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PeopleLiving;

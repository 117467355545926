// PreloadingPage.js
import React from 'react';

const Preloading = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <video autoPlay loop muted playsInline className="w-full h-full object-cover">
        <source src="/img/DVHomes.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Preloading;

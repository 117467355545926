import React, { useEffect } from 'react';

const Access = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the FeedBack component mounts
    });
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center mb-32">
        <div className="shadow-lg bg-slate-50 justify-between items-stretch max-w-[1170px] rounded-xl mt-40 flex w-full gap-5 px-10 py-9 max-md:max-w-full max-md:flex-wrap max-md:px-5">
          <div className="flex flex-col items-stretch max-md:max-w-full">
            <div className="text-primary1 text-3xl font-bold leading-[49.92px] mt-6 max-md:max-w-full max-md:text-4xl max-sm:text-3xl">ACCESSIBILITY AND NONDISCRIMINATION NOTICE</div>
            {/* <div className="text-slate-900 text-lg leading-8 mt-3 max-md:max-w-full">
                        
                        Let’s Make it Reality!
                    </div> */}
            <div>
              <p className="mt-5 leading-6">John L. Scott, Inc. ("John L. Scott") is committed to meeting the needs of all of its clients, and to complying with applicable laws related to discrimination. To that end, John L. Scott works to provide an accessible website and offers several services at no extra charge to its clients with disabilities or who might otherwise have difficulty accessing the information available on John L. Scott's website. These services include:</p>

              <ul className="list-decimal mt-6">
                <li className="mt-3">Access to agents by phone or in person. John L. Scott's agents are available to discuss your search criteria, review the status of your search, purchase, or sale, and explain certain materials. You can visit an agent in person or reach one by phone. To find an agent in your area, visit https://www.johnlscott.com/locator/office.</li>
                <li className="mt-3">Information reformatting. John L. Scott can provide some documents, such as real property listings, in alternative formats, such as large print or on a compact disc. You can call a John L. Scott agent in your area to learn more.</li>
                <li className="mt-3">Interpretation services. If your primary language is not English, John L. Scott may be able to provide an interpreter (such as an agent who speaks your language) or information written in your language.</li>
              </ul>
              <p className="mt-2">In addition to the services provided by John L. Scott, there are steps you can take to increase the accessibility of John L. Scott's website, and the Internet generally. Some steps that may be helpful are outlined on the Social Security Administration's website, which can be found here: https://www.ssa.gov/accessibility/.</p>
              <h3 className="mt-2 font-bold">I am blind or can't see very well</h3>
              <p className="mt-2">If you have trouble seeing web pages, the US Social Administration offers these tips (link is external) for optimizing your computer and browser to improve your online experience.</p>
              <ul className="list-[circle] mt-6">
                <li className="mt-3">Use your computer to read web pages out loud (https://www.ssa.gov/accessibility/browseAloud.html)</li>
                <li className="mt-3">Use the keyboard to navigate screens (https://www.ssa.gov/accessibility/keyboard_nav.html)</li>
                <li className="mt-3">Increase text size (https://www.ssa.gov/accessibility/textsize.html)</li>
                <li className="mt-3">Magnify your screen (https://www.ssa.gov/accessibility/magnifyscreen.html)</li>
                <li className="mt-3">Change background and text colors (https://www.ssa.gov/accessibility/changecolors.html)</li>
                <li className="mt-3">Make your mouse pointer more visible (Windows only) (https://www.ssa.gov/accessibility/mousepointer.html)</li>
              </ul>

              <h3 className="mt-4 font-bold">I find a keyboard or mouse hard to use</h3>
              <p className="mt-2">If you find a keyboard or mouse difficult to use, speech recognition software such as Dragon NaturallySpeaking (http://www.nuance.com/dragon/index.htm) may help you navigate web pages and online services. This software allows the user to move focus around a web page or application screen through voice controls.</p>

              <h3 className="mt-4 font-bold">I am deaf or hard of hearing</h3>
              <p className="mt-2">If you are deaf or hard of hearing, there are several accessibility features available to you.</p>
              <ul className="list-[circle] mt-6">
                <li className="mt-3 font-semibold">Transcripts</li>
              </ul>
              <p className="mt-2">A text transcript is a text equivalent of audio information that includes spoken words and non-spoken sounds such as sound effects. NAR is working on adding transcripts to all scripted video and audio content.</p>

              <ul className="list-[circle] mt-6">
                <li className="mt-3 font-semibold">Captioning</li>
              </ul>
              <p className="mt-2">A caption is transcript for the audio track of a video presentation that is synchronized with the video and audio tracks. Captions are generally rendered visually by being superimposed over the video, which benefits people who are deaf and hard-of-hearing, and anyone who cannot hear the audio (e.g., when in a crowded room). Most of NAR's video content includes captions. Learn how to turn captioning on and off in YouTube. (https://support.google.com/youtube/answer/100078?hl=en)</p>

              <ul className="list-[circle] mt-6">
                <li className="mt-3 font-semibold">Volume controls</li>
              </ul>
              <p className="mt-2">Your computer, tablet, or mobile device has volume control features. Each video and audio service has its own additional volume controls. Try adjusting both your device's volume controls and your media players' volume controls to optimize your listening experience.</p>
              <p className="mt-5">If the format of any material on John L. Scott's website interferes with your ability to access the information, or if you have any feedback on the website's accessibility, please contact John L. Scott at [contactus@johnlscott.com] and describe the nature of the issue. Please include the nature of the accessibility problem, the web address of the requested material, your preferred format in which to receive the material (e.g., large print, CD, different language), and your contact information. John L. Scott will work to provide you with the information you request in an accessible format.</p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Access;

import React, { useEffect } from 'react';
import Touch from './Touch';
import Office from './Office';

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the Contact component mounts
    });
  }, []);

  return (
    <div>
      <Touch />
      <Office />
    </div>
  );
};

export default Contact;

import React from 'react';
import { Fade } from 'react-awesome-reveal';

const LandImage = () => {
  const handleExploreClick = () => {
    // Scroll down by one page
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="flex-col justify-center items-center overflow-hidden object-contain relative h-screen flex px-20 py-12 max-md:px-5 max-sm:max-w-full">
      <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=100 100w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=200 200w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=400 400w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=800 800w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2F60bd44a890ac4356aa91264734464f16%2Fb2a3900f59094a7d911e6b31b74860ce?width=2000 2000w" className="absolute h-full top-0 opacity-100 left-0 w-full object-cover object-center inset-0" alt="dv" />
      <Fade direction="up">
        <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fc4ec573b0ccce0977e6fe01cde3ef844737e46e62f712cf2bb25d8a8217488?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w" className="aspect-[14.08] object-contain transition duration-500 cursor-default object-center w-full z-10 mt-20 overflow-hidden hover:opacity-75  max-w-[845px]" alt="dv" />
      </Fade>
      <div className="relative text-zinc-50 text-2xl font-mono leading-9 self-center mt-6 -mb-36 max-md:max-w-full">Enhance Living Standards.</div>
      <div className="z-40 mt-52 -mb-20">
        {/* <button className="bg-primary1 hover:bg-secondary1 transition duration-500 text-zinc-50 text-base font-semibold leading-5 self-center py-3 px-8 rounded-lg mt-4  z-50 ease-in-out" onClick={handleExploreClick}>
          Explore
        </button> */}
                        <button onClick={handleExploreClick} className=" justify-center text-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 self-center flex  max-w-full flex-col  mb-4 px-9 py-4 rounded-[32px] max-md:px-5">
              <div className="items-stretch flex justify-between gap-1.5 ">
                <div className="text-zinc-50 text-center text-base leading-6 grow whitespace-nowrap px-8 text-lg">  Explore</div>
              </div>
            </button>
      </div>
    </div>
  );
};
export default LandImage;

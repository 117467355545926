import { useState } from 'react';
import { TextField, FormControlLabel, FormLabel, RadioGroup, Radio, FormGroup, Typography, Box, Grid, FormHelperText } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

const Calendar = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    apartmentNumber: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    phoneNumber: '',
    selectedCalendars: '',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    apartmentNumber: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    phoneNumber: '',
    selectedCalendars: '', 
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.firstName) {
      formErrors.firstName = 'First Name is required';
      isValid = false;
    } else if (!/^[A-Za-z]+$/.test(formData.firstName)) {
      formErrors.firstName = 'First Name should contain only letters';
      isValid = false;
    }

    if (!formData.lastName) {
      formErrors.lastName = 'Last Name is required';
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.lastName)) {
      formErrors.lastName = 'Last Name should contain only letters and spaces';
      isValid = false;
    }

    if (!formData.streetAddress) {
      formErrors.streetAddress = 'Street Address is required';
      isValid = false;
    }
    if (!formData.city) {
      formErrors.city = 'City is required';
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.city)) {
      formErrors.city = 'City should contain only letters';
      isValid = false;
    }

    if (!formData.state) {
      formErrors.state = 'State is required';
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.state)) {
      formErrors.state = 'State should contain only letters';
      isValid = false;
    }

    if (!formData.zipCode) {
      formErrors.zipCode = 'Zip Code is required';
      isValid = false;
    } else if (!/^\d{5}$/.test(formData.zipCode)) {
      formErrors.zipCode = 'Zip Code must be exactly 5 digits';
      isValid = false;
    }

    if (!formData.email) {
      formErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid';
      isValid = false;
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = 'Phone Number is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      formErrors.phoneNumber = 'Phone Number must be exactly 10 digits';
      isValid = false;
    }

    if (!formData.selectedCalendars) {
      formErrors.selectedCalendars = 'Please select one calendar';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submission started');

    if (validateForm()) {
      console.log('Form validation passed');
      setLoading(true);

      try {
        console.log('Making API call...');
        const response = await axios.post('https://dvhomesfront.vercel.app/api/calendar', formData);
        console.log('API Response:', response);

        // Show success message first
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Your calendar request has been submitted successfully!',
          confirmButtonColor: '#28a745'
        });

        // Then reset the form
        setFormData({
          firstName: '',
          lastName: '',
          streetAddress: '',
          apartmentNumber: '',
          city: '',
          state: '',
          zipCode: '',
          email: '',
          phoneNumber: '',
          selectedCalendars: '',
        });

        console.log('Form reset completed');

      } catch (error) {
        console.error('Error details:', error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.message || 'Something went wrong. Please try again later.',
          confirmButtonColor: '#dc3545'
        });
      } finally {
        setLoading(false);
        console.log('Form submission completed');
      }
    } else {
      console.log('Form validation failed');
    }
  };

  return (
    <div>
      <h2 className="pt-28">Dream Vision Calendar 2025</h2>
      <Box className="max-w-4xl mx-auto px-6 bg-white rounded-lg shadow-lg mb-10 mt-0">
      <Typography variant="h6" align="center" className="pb-5">
          If you like to receive a copy of the Malayalam or English Calendar, Please submit your request.
        </Typography>
      <div className="flex lg:flex-row md:flex-col px-8 md:px-20 max-sm:flex-col sm:flex-col lg:gap-4 md:gap-6 md:mb-5 justify-center items-center">
        <img src="/img/malayalam.jpg" alt="Malayalam Calendar" className="lg:w-1/2 mb-4 md:mb-0" />
        <img src="/img/decenglish.jpeg" alt="English Calendar" className="lg:w-1/2" />
      </div>


        <form onSubmit={handleSubmit}>
          <div className="space-y-4 mt-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField label="First Name" variant="outlined" fullWidth name="firstName" value={formData.firstName} onChange={handleChange} error={Boolean(errors.firstName)} helperText={errors.firstName} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Last Name" variant="outlined" fullWidth name="lastName" value={formData.lastName} onChange={handleChange} error={Boolean(errors.lastName)} helperText={errors.lastName} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <TextField label="Street Address" variant="outlined" fullWidth name="streetAddress" value={formData.streetAddress} onChange={handleChange} error={Boolean(errors.streetAddress)} helperText={errors.streetAddress} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Apartment Number" variant="outlined" fullWidth name="apartmentNumber" value={formData.apartmentNumber} onChange={handleChange} error={Boolean(errors.apartmentNumber)} helperText={errors.apartmentNumber} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField label="City" variant="outlined" fullWidth name="city" value={formData.city} onChange={handleChange} error={Boolean(errors.city)} helperText={errors.city} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="State" variant="outlined" fullWidth name="state" value={formData.state} onChange={handleChange} error={Boolean(errors.state)} helperText={errors.state} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Zip Code" variant="outlined" fullWidth name="zipCode" value={formData.zipCode} onChange={handleChange} error={Boolean(errors.zipCode)} helperText={errors.zipCode} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField label="Email" variant="outlined" fullWidth name="email" value={formData.email} onChange={handleChange} error={Boolean(errors.email)} helperText={errors.email} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField label="Phone Number" variant="outlined" fullWidth name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} error={Boolean(errors.phoneNumber)} helperText={errors.phoneNumber} />
              </Grid>
            </Grid>

            <FormGroup>
              <FormLabel component="legend">Select Calendar</FormLabel>
              <RadioGroup
                name="selectedCalendars" 
                value={formData.selectedCalendars} 
                onChange={handleChange}
                error={Boolean(errors.selectedCalendars)} 
              >
                <FormControlLabel value="Malayalam" control={<Radio />} label="Malayalam Calendar" />
                <FormControlLabel value="English" control={<Radio />} label="English Calendar" />
                <FormControlLabel value="Both" control={<Radio />} label="Both " />
              </RadioGroup>
              {errors.selectedCalendars && <FormHelperText error>{errors.selectedCalendars}</FormHelperText>}
            </FormGroup>

            {formData.selectedCalendars === 'Malayalam' && (
              <div className="mt-6">
                <img src="/img/malayalam.jpg" alt="Malayalam Calendar" style={{ width: '300px', height: 'auto', margin: '0 auto', display: 'block' }} />
              </div>
            )}
            {formData.selectedCalendars === 'English' && (
              <div className="mt-6">
                <img src="/img/decenglish.jpeg" alt="English Calendar" style={{ width: '300px', height: 'auto', margin: '0 auto', display: 'block' }} />
              </div>
            )}

            <button 
              type="submit" 
              className="bg-primary1 hover:bg-secondary1 text-slate-50 font-medium uppercase rounded-full w-full text-lg px-5 py-2.5 text-center me-2 mb-2"
              onClick={handleSubmit}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>

            <Typography variant="body2" align="center" color="textSecondary" className="mt-6">
              *Disclaimer: The information provided may be used for promotional and marketing purposes by Dream Vision Company. You can opt out of receiving marketing communications at any time by following the unsubscribe instructions included in our emails. By continuing to interact with our content, you acknowledge and agree to the terms outlined above.
            </Typography>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default Calendar;

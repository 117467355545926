import React from 'react';

const Touch = () => {
  return (
    <div className="items-center flex flex-col justify-center px-16 -mt-9 py-12 max-md:px-5">
      <div className="flex w-full max-w-[1170px] flex-col items-stretch my-8 max-md:max-w-full">
        <div className="text-slate-900 text-3xl font-bold leading-10 self-center whitespace-nowrap">Visit our office</div>
        <div className="text-slate-500 text-lg leading-6 self-center whitespace-nowrap mt-4">Explore what services we offer</div>
        <div className="mt-12 max-md:max-w-full max-md:mt-10">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[30%] max-md:w-full max-md:ml-0">
              <div className="items-center bg-slate-50 flex w-full grow flex-col mx-auto px-6 py-7 rounded-lg border-solid max-md:mt-6 max-md:px-5 border border-transparent hover:border-secondary1">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/91e8e8fcf5c11ab48e419501345a108e322f0a6cefa6c0ff0092d05aacad57e3?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full" alt="dv" />
                <div className="text-slate-900 text-center text-lg font-medium leading-6 whitespace-nowrap mt-6">customer care</div>
                <div className="self-stretch text-slate-500 text-center text-base leading-6 whitespace-nowrap mt-1.5">206-338-4663</div>
                <div className="justify-center items-center self-stretch border transition duration-500 hover:bg-secondary1 bg-primary1  flex w-full flex-col mt-8 px-16 py-4 rounded-lg border-solid max-md:px-5">
                  <div className="flex gap-2.5 items-start">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/19170b868e97c4500486970fc8d21eea6852842c1f6b8c8b00a879947ec438fb?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                    <a href="tel:2063384668" className="text-zinc-50 text-center text-base leading-5  grow whitespace-nowrap">
                      Call us now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[41%] ml-5 max-md:w-full max-md:ml-0">
              <div className="items-center bg-stone-50 flex grow flex-col w-full px-6 py-7 rounded-lg max-md:max-w-full max-md:mt-6 max-md:px-5 border border-transparent hover:border-secondary1">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/34d33faffe9ac412a1d88b738ea7c8d98949da0aaf9d10b87653cf02cef0d470?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full" alt="dv" />
                <div className="text-slate-900 text-center text-lg font-medium leading-6 whitespace-nowrap mt-6">Office Address</div>
                <div className="self-stretch text-slate-500 text-center text-base leading-6 whitespace-nowrap mt-1.5">26943 168th Pl SE, Covington, WA 98042</div>
                <div className="justify-center items-center self-stretch border transition duration-500  hover:bg-secondary1 bg-primary1  flex w-full flex-col mt-8 px-16 py-4 rounded-lg border-solid max-md:px-5">
                  <div className="flex gap-2.5 items-start">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6d092ccf9eda081b866b6465a8626a95881fc6c9c9d1c9a775209f2388a9b9f9?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                    <a href="https://maps.app.goo.gl/LH9aGvjrcmvFU69q8" target="blank" className="text-zinc-50 text-center text-base leading-5 grow whitespace-nowrap">
                      Get direction
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[30%] ml-5 max-md:w-full max-md:ml-0">
              <div className="items-center bg-stone-50 flex w-full grow flex-col mx-auto px-6 py-7 rounded-lg max-md:mt-6 max-md:px-5  border border-transparent hover:border-secondary1">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5753649e8a5aae2108870eaf454d98ab80f3973de5c7921cdaf89043452f84c9?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-20 overflow-hidden max-w-full" alt="dv" />
                <div className="text-slate-900 text-center text-lg font-medium leading-6 whitespace-nowrap mt-6">Need Support?</div>
                <div className="self-stretch text-slate-500 text-center text-base leading-6 whitespace-nowrap mt-1.5">team@dreamvisionhomes.com</div>
                <div className="justify-center items-center self-stretch border transition duration-500  hover:bg-secondary1 bg-primary1  flex w-full flex-col mt-8 px-16 py-4 rounded-lg border-solid max-md:px-5">
                  <div className="flex gap-2.5 items-start">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1cfb21879c0ac8bdfd37d081bcf953d5f0716e4f19d55e64a012a618ecfac59?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full" alt="dv" />
                    <a href="mailto:team@dreamvisionhomes.com" className="text-zinc-50 text-center text-base leading-5  grow whitespace-nowrap">
                      Mail us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Touch;
